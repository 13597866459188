//@ts-ignore
import { CommonModule, DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, inject, Inject, OnInit, Output, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AlertController, IonModal, LoadingController, ModalController, ToastController } from '@ionic/angular';
import Fuse from 'fuse.js';
import { ICreateOrderRequest, IPayPalConfig, NgxPayPalModule } from 'ngx-paypal';
import { Producto } from '../../Models/Productos';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { ConektaService } from '../../services/conekta.service';
import { LoginService } from '../../services/login.service';
import { UsuarioService } from '../../services/usuario.service';
//import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import Swal from 'sweetalert2';
import { FormInvitadoComponent } from '../../components/form-invitado/form-invitado.component';
import { ModalCargaComponent } from '../../components/modal-carga/modal-carga.component';
import { APPCONTEXTService } from '../../services/app-context.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { TiktokEventsService } from '../../services/tiktok-events.service';
import { GetCarritosResponse, ItemCarrito } from '../../types/checkoutService';

import { firstValueFrom } from 'rxjs';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { CartData, ValidarInvService } from '../../services/validar-inv.service';

//La url no debe llevar slash (/) al final
const NEWAPI_BASE_URL = 'https://lideart.aytcloud.com/ApisEcommerce';

interface infoInvitado {
  email: string,
  nombre: string,
  phone: string,
  envio: boolean,
  cp?: string,
  colonia?: string,
  ciudad?: string,
  estado?: string,
  direccion?: string
}

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatSlideToggleModule,
    NgxPayPalModule,
    ModalCargaComponent,
    SpinnerComponent,
    FormInvitadoComponent
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
  providers: [DatePipe, ApiProductosService, CarritoServiceService, LoginService, ConektaService, UsuarioService],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CheckoutComponent implements OnInit {
  // Este motivo de venta debe de variar para identificar si se hace una compra en la APP o en WEB 
  // MOTV_00032 = WEB
  // MOTV_00034 = APP
  @Output() close = new EventEmitter<void>();
  private MOT_VENTA = this.APP_CONTEXT.isApp ? 'MOTV_00034' : 'MOTV_00032';

  private _valCarrito = inject(ValidarInvService)

  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public showCancel: boolean = false;
  public showError: boolean = false;

  mercadoWasOpen = false //Variable utilizada para evitar un re-renderizado (Ya no volvia a aparecer) al momento de cambiar entre metodos de pago 

  //Mercado Pago
  MP_isCompletePayment = false
  MP_preferencePayment = ''
  MP_cotizacionPre = ''
  MP_isLoading = true;
  isModalOpen = false;
  customActionSheetOptions = {
    header: 'Uso CFDI',
    subHeader: 'Selecciona el uso de CFDI',
  };
  customActionSheetOptions3 = {
    header: 'Metodo de Pago',
    subHeader: 'Selecciona el metodo de pago',
  };

  customActionSheetOptions2 = {
    header: 'Regimen Fiscal',
    subHeader: 'Selecciona el regimen fiscal',
  };

  // isModalOpen = false;
  isModalOpen2 = false;
  isModalOpen3 = false;
  isModalOpen4 = false;

  @ViewChild(IonModal) modal!: IonModal;
  @ViewChild(IonModal) modal2!: IonModal;
  @ViewChild(IonModal) modal3!: IonModal;
  @ViewChild(IonModal) modal4!: IonModal;
  @ViewChild(IonModal) modal5!: IonModal;



  //public payPalConfig ? : IPayPalConfig;
  //  public showSuccess: boolean  = false;
  //  public showCancel: boolean = false;
  //  public showError: boolean = false;
  public product: any;
  public searchbarVisible: boolean = false;
  usuario!: Usuario;
  public grandTotal!: number;
  nombre!: string;
  apellido!: string;
  datosUsuario: any = [];
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  nombreEstado: any;
  public numeroResultados: number = 0;
  direccion: any = [];
  radioDireccion = 0;
  checarDireccion = true;
  lineas: any = [];
  lineasBomnitas: any = [];
  textoCargando = "";
  public respuestaCotizacion: string = "";
  lineasProductosPaqueterias: any = [];
  cotDatos: any;
  items: Producto[] = [];
  itemsillos: ItemCarrito[] = []
  peso: any;
  jsonOrigen: any;
  paqueteExpress: any;
  estafeta: any;
  recoge = false;
  valorRadio = 0;
  productitos: any = []
  valorPaqueteExpress = true;
  iframe = "";
  datosWebPay: any = [];
  carrito: any;
  urlsafe!: SafeResourceUrl;
  tipoEnvio = "";
  radioEnvio = false;
  respuestapago: any;
  respuesta: any = [];
  valorEnvio: any;
  radioPago = true;
  metodoPago = "PayPal"; //Se define paypal como metodo de pago por default
  postcode = "";
  address1 = ""
  id_state = ""
  city = ""
  colony = "";
  name!: string;
  totalEnvio = 0;
  facturame = false;
  facturame2 = false;
  paymentMethod: any;
  PaymentTermsName: any;
  countyId: any;
  modoEntrega: any;
  codigo: any;
  datoscounty: any = []
  respuestillaOV: any = [];
  respuestaOV: string | null = null;
  datosdelcarro: any = [];
  calculo = 0;
  numero_carro: any;
  cargando = false;
  sepuede = false;
  arregloitems: any[] = [];
  urlconekta = "";
  iframeconekta!: SafeResourceUrl;
  paquetaxos: any = [];
  arreglito: any;
  codigoPostal: any;
  oxxxo = "";
  metodinPago = "00";
  respFact: any;
  noCuenta = '65-50630503-1';
  clabe = '014150655063050317';
  banco = 'Santander';
  jsonMensaje: any;
  porcentaje = 0;
  email: any;
  htmlfactura: any;
  alias: any;
  empresa: any;
  telefono = ""
  telefonito: any;
  zipcode: any;
  address1Form: any;
  address2: any;
  id_stateForm: any;
  cityForm: any;
  colonyForm: any;
  estado: any;
  razonSocial: any;
  rfc: any;
  DirFiscal: any;
  estadoFactura: any;
  ciudadFactura: any;
  coloniaFactura: any;
  FormaPago: any;
  UsoCFDI: any;
  customerID: any;
  usocfdi = "S01";
  regimenfiscal = "616";
  now!: string;
  public fechaActual: number = Date.now();
  public fechaActual2: number = Date.now();
  cliente: any = [];
  orden: any = [];
  formattedDate!: any;
  facturitadatos: any = [];
  objetoOrdenVenta: any;
  mensajazo: any;
  objetoCompra: any;
  porcentajeparalinea: any;
  mensajepasa = "";
  medioErrorAlv = 0;
  totalPaypal = "";
  intervalopago: any;
  acepto = false;
  currentDateTime2: any
  totalpaypalsito: any;
  faltaInformacionDireccion = false;
  codiguito: any;
  arrayCarrito: GetCarritosResponse[] = []
  subdistribuidor: any;
  isInvitado = this.router.url.includes('invitado');
  formInvitado: infoInvitado | null = null;
  isEnvio: boolean = false;
  correosConfirmacion: string[] = [];
  private nombreCompleto = ''
  private _idCompra = 0;

  private bricksBuilder: any

  etiquetas: any = [];
  arrayEtiquetas: any;
  numeroDeEtiquetas: any = [];
  @ViewChild('paypalcont') paypalcont!: ElementRef<HTMLInputElement>;
  paypalIs = false;

  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;

  @ViewChild('modal_nd') modal_nd!: ElementRef<HTMLInputElement>;
  modalNdIs = false;

  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  constructor(public usuarioService: LoginService,
    private APP_CONTEXT: APPCONTEXTService,
    private cdr: ChangeDetectorRef,
    private checkoutService: CheckoutServiceService,
    private shoppingCart: CarritoServiceService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public toastController: ToastController, public modalCtrl: ModalController,
    public loadingController: LoadingController, /*private payPal: PayPal*/ public http: HttpClient, public renderer: Renderer2,
    private conekta: ConektaService,
    private productoService: ApiProductosService, public alertController: AlertController, private datePipe: DatePipe,
    private tiktokEvents: TiktokEventsService, //Importar tiktokEvents y enviar evento de addPayment,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object, private apiFacebook: FacebookApiService) {

    this.now = new Date().toString().split(' ')[4];
    const currentDate = new Date();
    this.formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.currentDateTime2 = this.datePipe.transform((new Date), 'yyyy-MM-dd h:mm:ss')

  }

  private currentItems: CartData[] = [];

  ngOnInit() {

    this.shoppingCart.cartItems.subscribe(data => {
      if (data.length > 0) {

        this.currentItems = data;
        this.itemsillos = data as unknown as ItemCarrito[];
        console.log('Itemsillos', this.itemsillos);
        if (this.itemsillos) {

          let subs1 = 0;
          let total = 0;

          for (const item of this.itemsillos) {
            subs1 += item.weight * item.quantity;
            total += item.precio * item.quantity;
            this.peso = subs1;

          }
          this.grandTotal = total;
        }
        const _transformData = this._valCarrito.convertCartDataToInputProduct(this.currentItems);
        //Valida que haya stock en TODOS los productos del carrito
        this._valCarrito.isAllWithStock({ products: _transformData }).then((res) => {
          // alert('Respuesta de stock: ' + res);
          if (!res) {
            this.router.navigate(['/cart']);
            return;
          }
        });
      }
    })

    this.textoCargando = "Cargando Direcciones..."
    if (isPlatformBrowser(this.platformId)) {



      //@ts-expect-error
      const mp = new MercadoPago('TEST-a8bdb1bc-b9c1-40a7-a18a-4c6f746bb92d', {
        locale: 'es'
      });
      const bricksBuilder = mp.bricks();
      this.bricksBuilder = bricksBuilder;
      this.usuarioService.usuario.subscribe((res: any) => {
        if (res) {
          this.usuario = res;
          this.isInvitado = false;
          if (this.router.url.includes('invitado')) this.router.navigateByUrl('/checkout');
        } else {
          this.usuario = { usuario: 'dptosistemasavance@gmail.com', token: '' }
          this.isInvitado = true;
        }




        this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
          this.datosUsuario = data;
          //console.log(this.datosUsuario);
          this.nombre = this.datosUsuario.nombre;
          this.apellido = this.datosUsuario.apellido;
          this.customerID = this.datosUsuario.customerID;
          //console.log('cicla 5'); 
          this.cargando = true;

          this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
            try {

              this.direccion = res;

              this.apiFacebook.sendEvent('PageView', undefined, this.usuario.usuario, this.direccion[0].phone, {
                external_id: this.datosUsuario.customerID,
                nombre: this.direccion[0].firstname,
                apellido: this.direccion[0].lastname,
                estado: this.direccion[0].id_state,
                ciudad: this.direccion[0].city,
                country: 'México',
                codigo_postal: this.direccion[0].postcode
              });

              //console.log(this.direccion)
              this.telefonito = this.direccion[0].phone;
              console.log('NombreEstado', this.nombreEstado);
              // for (let i = 0; i < this.direccion.length; i++) {
              //   const element = this.direccion[i].id_state;
              //   if (element == 57) {
              //     this.nombreEstado = "Aguascalientes"
              //   }
              //   if (element == 58) {
              //     this.nombreEstado = "Baja California"
              //   }
              //   if (element == 59) {
              //     this.nombreEstado = "Baja California Sur"
              //   }
              //   if (element == 60) {
              //     this.nombreEstado = "Campeche"
              //   }
              //   if (element == 61) {
              //     this.nombreEstado = "Chiapas"
              //   }
              //   if (element == 62) {
              //     this.nombreEstado = "Chihuahua"
              //   }
              //   if (element == 63) {
              //     this.nombreEstado = "Coahuila"
              //   }
              //   if (element == 64) {
              //     this.nombreEstado = "Colima"
              //   }
              //   if (element == 65) {
              //     this.nombreEstado = "Distrito Federal"
              //   }
              //   if (element == 66) {
              //     this.nombreEstado = "Durango"
              //   }
              //   if (element == 67) {
              //     this.nombreEstado = "Guanajuato"
              //   }
              //   if (element == 68) {
              //     this.nombreEstado = "Guerrero"
              //   }
              //   if (element == 69) {
              //     this.nombreEstado = "Hidalgo"
              //   }
              //   if (element == 70) {
              //     this.nombreEstado = "Jalisco"
              //   }
              //   if (element == 71) {
              //     this.nombreEstado = "Estado de Mexico"
              //   }
              //   if (element == 72) {
              //     this.nombreEstado = "Michoacan"
              //   }
              //   if (element == 73) {
              //     this.nombreEstado = "Morelos"
              //   }
              //   if (element == 74) {
              //     this.nombreEstado = "Nayarit"
              //   }
              //   if (element == 75) {
              //     this.nombreEstado = "Nuevo Leon"
              //   }
              //   if (element == 76) {
              //     this.nombreEstado = "Oaxaca"
              //   }
              //   if (element == 77) {
              //     this.nombreEstado = "Puebla"
              //   }
              //   if (element == 78) {
              //     this.nombreEstado = "Queretaro"
              //   }
              //   if (element == 79) {
              //     this.nombreEstado = "Quintana Roo"
              //   }
              //   if (element == 80) {
              //     this.nombreEstado = "San Luis Potosi"
              //   }
              //   if (element == 81) {
              //     this.nombreEstado = "Sinaloa"
              //   }
              //   if (element == 82) {
              //     this.nombreEstado = "Sonora"
              //   }
              //   if (element == 83) {
              //     this.nombreEstado = "Tabasco"
              //   }
              //   if (element == 84) {
              //     this.nombreEstado = "Tamaulipas"
              //   }
              //   if (element == 85) {
              //     this.nombreEstado = "Tlaxcala"
              //   }
              //   if (element == 86) {
              //     this.nombreEstado = "Veracruz"
              //   }
              //   if (element == 87) {
              //     this.nombreEstado = "Yucatan"
              //   }
              //   if (element == 88) {
              //     this.nombreEstado = "Zacatecas"
              //   }

              // }
            } catch (error) {
              this.medioErrorAlv = 1;
              this.cargando = false;
            }
            try {



              if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
                //!TODO: La consulta se pudiesee quitar, pero decido manterla para que quede como un log de datos en la tabla de compra. (Trae mas que solo los items)
                this.checkoutService.getCarritosItems(this.usuario.usuario).subscribe(data => {
                  this.arrayCarrito = data;

                  //prueba


                  //TODO: el tipo esta mal el tipo de dato que esta puesto deberia de ir en carrito_guardado
                  //this.itemsillos = currentItems as unknown as ItemCarrito[];
                  if (this.itemsillos) {

                    let subs1 = 0;

                    for (const item of this.itemsillos) {
                      subs1 += item.weight * item.quantity;

                      this.peso = subs1;

                    }

                    let subs2 = 0;

                    let jsonCupon = { "email": this.usuario.usuario }
                    this.usuarioService.revisaDescuento(jsonCupon).subscribe(codigos => {


                      this.datosdelcarro = codigos;

                      if (this.datosdelcarro && this.datosdelcarro.length > 0) {
                        this.codiguito = this.datosdelcarro[0].codigo;
                      } else {
                        console.warn('datosdelcarro está indefinido o vacío');
                        this.codiguito = null; // O cualquier valor por defecto que tenga sentido en tu contexto
                      }

                      if (this.datosdelcarro && this.datosdelcarro.length > 0) {
                        this.numero_carro = this.datosdelcarro[0].id_cart;
                      } else {
                        console.warn('datosdelcarro está indefinido o vacío');
                        this.numero_carro = null; // O cualquier valor por defecto que tenga sentido en tu contexto
                      }


                      if (this.datosdelcarro[0].descuento == 1) {
                        for (const item of this.itemsillos) {
                          subs2 += item.precio * item.quantity;

                          this.porcentaje = this.datosdelcarro[0].porcentaje;

                          this.grandTotal = subs2;
                          this.calculo = this.grandTotal * this.datosdelcarro[0].porcentaje;
                          this.grandTotal = this.grandTotal - this.calculo;


                        }
                      } else {
                        for (const item of this.itemsillos) {
                          subs2 += item.precio * item.quantity;

                          this.grandTotal = subs2;
                          this.grandTotal = this.grandTotal + this.totalEnvio;
                        }
                      }

                      try {
                        setTimeout(() => {
                          document.getElementById('radio0')?.click()
                        }, 500);

                      } catch (error) {
                        this.cargando = false;
                      }
                      this.cargando = false;
                    })

                  }
                  this.calculaPaqueterias();
                  this.cargando = false;
                });


              }
            } catch (error) {
              this.medioErrorAlv = 1;
              this.cargando = false;
            }

            this.cargando = false;
          })
        })

        this.cargando = false;
      });

      this.cargando = false;

    }








  }

  getNombreEstado(id_state: number): string {
    let nombreEstado = "";
    if (id_state == 57) {
      nombreEstado = "Aguascalientes";
    } else if (id_state == 58) {
      nombreEstado = "Baja California";
    } else if (id_state == 59) {
      nombreEstado = "Baja California Sur";
    } else if (id_state == 60) {
      nombreEstado = "Campeche";
    } else if (id_state == 61) {
      nombreEstado = "Chiapas";
    } else if (id_state == 62) {
      nombreEstado = "Chihuahua";
    } else if (id_state == 63) {
      nombreEstado = "Coahuila";
    } else if (id_state == 64) {
      nombreEstado = "Colima";
    } else if (id_state == 65) {
      nombreEstado = "Distrito Federal";
    } else if (id_state == 66) {
      nombreEstado = "Durango";
    } else if (id_state == 67) {
      nombreEstado = "Guanajuato";
    } else if (id_state == 68) {
      nombreEstado = "Guerrero";
    } else if (id_state == 69) {
      nombreEstado = "Hidalgo";
    } else if (id_state == 70) {
      nombreEstado = "Jalisco";
    } else if (id_state == 71) {
      nombreEstado = "Estado de Mexico";
    } else if (id_state == 72) {
      nombreEstado = "Michoacan";
    } else if (id_state == 73) {
      nombreEstado = "Morelos";
    } else if (id_state == 74) {
      nombreEstado = "Nayarit";
    } else if (id_state == 75) {
      nombreEstado = "Nuevo Leon";
    } else if (id_state == 76) {
      nombreEstado = "Oaxaca";
    } else if (id_state == 77) {
      nombreEstado = "Puebla";
    } else if (id_state == 78) {
      nombreEstado = "Queretaro";
    } else if (id_state == 79) {
      nombreEstado = "Quintana Roo";
    } else if (id_state == 80) {
      nombreEstado = "San Luis Potosi";
    } else if (id_state == 81) {
      nombreEstado = "Sinaloa";
    } else if (id_state == 82) {
      nombreEstado = "Sonora";
    } else if (id_state == 83) {
      nombreEstado = "Tabasco";
    } else if (id_state == 84) {
      nombreEstado = "Tamaulipas";
    } else if (id_state == 85) {
      nombreEstado = "Tlaxcala";
    } else if (id_state == 86) {
      nombreEstado = "Veracruz";
    } else if (id_state == 87) {
      nombreEstado = "Yucatan";
    } else if (id_state == 88) {
      nombreEstado = "Zacatecas";
    } else if (id_state == null || id_state == undefined) {
      nombreEstado = "";
    }
    return nombreEstado;
  }

  facturado() {
    if (this.facturame == false) {
      this.facturame = true;

    } else {
      this.facturame = false;
    }

  }

  handleWP() {
    if (this.modal_wpIs == false) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
    }
  }

  facturado2() {
    if (this.facturame2 == false) {
      this.facturame2 = true;
      //console.log(this.facturame2);
    } else {
      this.facturame2 = false;
      //console.log(this.facturame2);
    }

  }

  openModalNd() {
    if (this.modalNdIs == false) {
      this.renderer.setStyle(this.modal_nd.nativeElement, 'display', 'flex');

      this.modalNdIs = true;
    } else if (this.modalNdIs == true) {
      this.renderer.setStyle(this.modal_nd.nativeElement, 'display', 'none');

      this.modalNdIs = false;
    }
  }

  async handleBraintreePayment(nonce: any) {

    this.makePaymentRequest(this.grandTotal.toFixed(2), nonce).then((transaction) => {
      //console.log('Transaction', transaction);
    });

    /*this.api.makePaymentRequest(this.payableAmount, nonce).then((transaction) => {
      //console.log('Transaction', transaction);
    });*/
  }

  async makePaymentRequest(amount: any, nonce: any) {
    const paymentDetails = {
      paymentAmount: amount,
      nonceFromTheClient: nonce
    }
    return await this.http.post('https://app.lideart.net/checkoutWithPayment', paymentDetails).toPromise();
  }

  agregarDir() {
    var ciudad = (<HTMLSelectElement>document.getElementsByName("city")[0]).selectedOptions[0].innerText;
    this.city = ciudad;
    var colonia = (<HTMLSelectElement>document.getElementsByName("colony")[0]).selectedOptions[0].innerText;
    var estado = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
    var nc = colonia.split(' ')
    nc.pop()
    var coloniaFinal = nc.join(' ')
    this.colony = coloniaFinal;
    this.estado = estado;
    let arregloDir = {
      "alias": this.alias,
      "phonenumber": this.telefono,
      "zipcode": this.zipcode,
      "address1": this.address1,
      "address2": this.address2,
      "stateValue": this.estado,
      "cityValue": this.city,
      "colony": coloniaFinal,
      "correo": this.usuario.usuario
    }
    this.usuarioService.enviaDireccion(arregloDir).subscribe(datos => {
      location.reload();
    })

  }

  cerrarND() {
    location.reload();
  }

  guardaDatosFactura() {
    let arregloDir = {
      "email": this.usuario.usuario,
      "uso_cfdi": this.usocfdi,
      "razon_social": this.razonSocial,
      "rfc": this.rfc,
      "cp": this.codigoPostal,
      "direccion_fiscal": this.DirFiscal,
      "metodo_pago": this.metodinPago,
      "regimen_fiscal": this.regimenfiscal
    }
    this.usuarioService.guardaDatosFactura(arregloDir).subscribe(datosFact => {
      this.respFact = datosFact;
      if (datosFact == 0) {
        this.presentAlertConfirm3();
      } else {
        this.presentAlertConfirm4();
      }



    })
  }




  agregarDatosFacturacion() {

    let arregloDir = {
      "razonSocial": this.razonSocial,
      "rfc": this.rfc,
      "DirFiscal": this.DirFiscal,
      "FormaPago": this.FormaPago,
      "UsoCFDI": this.UsoCFDI
    }
    this.usuarioService.enviaDireccion(arregloDir).subscribe(datos => {
      this.cancel3();
    })

  }

  accionesDireccion(valor: any, accion: any) {

    if (valor != '' && valor != undefined) {

    }

    switch (accion) {
      case 'zipcode':
        if (valor.length >= 4) {
          this.porZipCode(valor.value)
        }
        break;
      case 'state':
        this.porState(valor.value)
        break;
      case 'city':
        this.porCity(valor.value)
        break;
      case 'colony':
        this.porColony()
        break;
      case 'edicion_direccion':
        // this.paraEditar(valor)
        break;
      default:
        break;
    }

  }

  porZipCode(postcode: any) {

    this.usuarioService.selectDireccionZipCode('zipcode', this.zipcode).subscribe(res => {
      this.respuesta = res;

      let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      if (this.respuesta.estado > 0) {
        (<HTMLInputElement>document.getElementsByName('id_state')[0]).value = this.respuesta.estado
        // this.direccionForm.controls.stateValue.setValue(this.respuesta.estado);
        this.estado = this.respuesta.estado

        const ciudades = this.respuesta.ciudades
        const ciudad = this.respuesta.ciudad
        for (var i = 0; i < ciudades.length; i++) {
          if (ciudades[i] == ciudad) {
            textoOpciones += '<option value="' + ciudades[i] + '" selected>' + ciudades[i] + '</option>'
          } else {
            textoOpciones += '<option value="' + ciudades[i] + '">' + ciudades[i] + '</option>'
          }
        }
        document.getElementsByName('city')[0].innerHTML = textoOpciones
        // this.direccionForm.controls.cityValue.setValue(ciudades[i]);
        textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
        const colonias = this.respuesta.colonias
        for (var i = 0; i < colonias.length; i++) {
          textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
        }
        document.getElementsByName('colony')[0].innerHTML = textoOpciones
        // this.direccionForm.controls.colonyValue.setValue(colonias[i]['colonia']);



      } else {
        (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedIndex = 0;
        document.getElementsByName('city')[0].innerHTML = textoOpciones;
        // this.direccionForm.controls.cityValue.setValue(textoOpciones);
        (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedIndex = 0;
        document.getElementsByName('colony')[0].innerHTML = textoOpciones;
        // this.direccionForm.controls.colonyValue.setValue(textoOpciones);
        (<HTMLSelectElement>document.getElementsByName('colony')[0]).selectedIndex = 0;
      }
      // this.SpinnerService.hide();

    });
  }

  porState(state: string) {
    // this.SpinnerService.show();
    // this.direccionForm.controls.zipcode.reset;
    this.usuarioService.selectDireccionState('porState', this.estado).subscribe(res => {
      this.respuesta = res;

      let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      const ciudades = this.respuesta.ciudades
      for (var i = 0; i < ciudades.length; i++) {
        textoOpciones += '<option value="' + ciudades[i] + '">' + ciudades[i] + '</option>'
      }
      document.getElementsByName('city')[0].innerHTML = textoOpciones
      // this.direccionForm.controls.cityValue.setValue(textoOpciones);

      textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
      const colonias = this.respuesta.colonias
      for (var i = 0; i < colonias.length; i++) {
        textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
      }
      document.getElementsByName('colony')[0].innerHTML = textoOpciones

      // colony
      // this.SpinnerService.hide();
    });
  }



  porCity(city: string) {
    // this.SpinnerService.show();
    // this.direccionForm.controls.zipcode.reset;
    // (<HTMLElement>document.getElementsByName('postcode')[0]).value = ""
    this.usuarioService.selectDireccionCity("porCity", this.city).subscribe(res => {
      this.respuesta = res;
      // this.SpinnerService.hide();
    })


    let textoOpciones = '<option value="" disabled="" >-- por favor elija --</option>'
    const colonias = this.respuesta.colonias
    for (var i = 0; i < colonias.length; i++) {
      textoOpciones += '<option value="' + colonias[i]['colonia'] + '">' + colonias[i]['colonia'] + ' (' + colonias[i]['codigo_postal'] + ')</option>'
    }
    document.getElementsByName('colony')[0].innerHTML = textoOpciones
    this.porColony();
    // this.SpinnerService.hide();
  }

  porColony() {
    var algo = document.getElementsByName('colony');

    if (algo.length > 0) {
      var opcion = <HTMLSelectElement>algo[0]
      var texto = opcion.selectedOptions[0].innerText
      var arr_codigo_postal = texto.split(' ')
      var codigo_postal = arr_codigo_postal[arr_codigo_postal.length - 1];
      codigo_postal = codigo_postal.replace(/\(/g, '').replace(/\)/g, '');
      (<HTMLInputElement>document.getElementsByName('zipcode')[0]).value = codigo_postal
      this.zipcode = codigo_postal;

    }
    // this.SpinnerService.hide();
    // //console.log(this.direccionForm.controls.colonyValue)
    // 
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones del servicio antes de seleccionar metodo de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm3() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Esta cuenta ya tiene datos de facturacion guardados',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm4() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Exito!',
      message: 'Datos de Facturacion guardados correctamente',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertConfirm2() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }


  private initConfig(): void {




    this.payPalConfig = {


      currency: 'MXN',
      clientId: 'AV3FnonRlJMuV_2PPWLJucZbVSMwTPeU6hDfMOnSQHZ2TWR6pBK7E9xAZLy3VPqCsWLOdquZk8Vfz-0Z', //! No olvidar cambiar
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          custom_id: this._idCompra.toString(),
          amount: {
            currency_code: 'MXN',
            value: this.totalpaypalsito.toString(),
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: this.totalpaypalsito.toString(),

              }
            }
          },
          // items: this.getItemsCarrito()
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{ name: 'locale', value: 'es_MX' }],
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        if (this.metodoPago == "PayPal") {
          this.valorRadio = 4;
          this.metodoPago = "PayPal";
          this.PaymentTermsName = "CONTADO";
          this.paymentMethod = "03";

          actions.order.get().then((details: any) => {

          });
          this.relizarCompra();
        }
        else {
          this.http.post('https://ntfy.sh/XJdfDUWnCLoBZG5v', `Se recibio una compra en PAYPAL con un metodo de pago distinto. El cliente podria NO generar OV. Monto: ${this.totalpaypalsito}, Correo: ${this.usuario.usuario}`).subscribe(r => {

          })
        }
      },
      onClientAuthorization: (data) => {

        this.showSuccess = true;
      },
      onCancel: (data, actions) => {

        this.showCancel = true;

      },
      onError: (err: any) => {

        this.showError = true;
      },
      onClick: (data, actions) => {

        // this.resetStatus();
      }
    };
  }

  /* AQUI INICIA PAYPAL 
  paymentAmount: string = '3.33';
  currency: string = 'INR';
  currencyIcon: string = '₹';
   
  payWithPaypal() {
    this.payPal.init({
      PayPalEnvironmentProduction: 'YOUR_PRODUCTION_CLIENT_ID',
      PayPalEnvironmentSandbox: 'YOUR_SANDBOX_CLIENT_ID'
    }).then(() => {
      // Environments: PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
      this.payPal.prepareToRender('PayPalEnvironmentSandbox', new PayPalConfiguration({
      // Only needed if you get an "Internal Service Error" after PayPal login!
      //payPalShippingAddressOption: 2 // PayPalShippingAddressOptionPayPal
      })).then(() => {
        let payment = new PayPalPayment(this.paymentAmount, this.currency, 'Description', 'sale');
        this.payPal.renderSinglePaymentUI(payment).then((res) => {
          //console.log(res);
          // Successfully paid
        }, () => {
          // Error or render dialog closed without being successful
        });
        }, () => {
           // Error in configuration
        });
    }, () => {
      // Error in initialization, maybe PayPal isn't supported or something else
    });
  }
   AQUI TERMINA PAYPAL */

  /*private initConfig(): void {
    this.payPalConfig = {
     
        currency: 'MXN',
        clientId: 'AStFqSiMlHe49OfnXWGV0AUbzDcRVyjJcQ_wo4fIrfvbwmjUyWOhalIm1AAJuUqY_foVZFT9Ts8_lkP0',
        createOrderOnClient: (data) => < ICreateOrderRequest > {
           intent: 'CAPTURE',
           purchase_units: [{
                amount: {
                    currency_code: 'MXN',
                   value: (this.grandTotal+(this.totalEnvio*1.16)).toFixed(2).toString(),
                    breakdown: {
                        item_total: {
                            currency_code: 'MXN',
                            value: (this.grandTotal+(this.totalEnvio*1.16)).toFixed(2).toString(),
                           
                      }
                    }
                },
                items: this.getItemsCarrito()
            }]
        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
       onApprove: (data, actions) => {

         
         
            actions.order.get().then((details: any) => {
             
            });
            this.relizarCompra();

        },
        onClientAuthorization: (data) => {
         
           this.showSuccess = true;
        },
        onCancel: (data, actions) => {
      
            this.showCancel = true;

        },
        onError: err => {
         
            this.showError = true;
        },
        onClick: (data, actions) => {
          
//            // this.resetStatus();
        }
    };
}*/

  getItemsCarrito(): any[] {
    const items: any[] = [];
    let item = {};
    this.itemsillos.forEach((it) => {
      item = {
        name: it.title,
        quantity: it.quantity,
        unit_amount: { value: (it.precio * 1.16).toFixed(2), currency_code: 'MXN' }
      };
      items.push(item);
    });
    let envio = {};
    envio = {
      name: 'Paquete Express',
      quantity: '1',
      unit_amount: { value: (this.totalEnvio * 1.16).toFixed(2), currency_code: 'MXN' }
    }
    items.push(envio);

    return items;
  }

  getItemsCarrito2() {

    let item = {};
    this.itemsillos.forEach((it) => {
      item = {
        name: it.title,
        unit_price: (it.precio * 100).toFixed(2),
        quantity: it.quantity
      }
      this.arregloitems.push(item);

    })




  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  continuarPersonal() {
    document.getElementById("personal")!.style.display = "none";
    document.getElementById("direccion")!.style.display = "block";
  }

  confirmarDireccion() {
    // AQUI-
    const estadoSeleccionado: string | undefined = this.getNombreEstado(this.direccion[this.radioDireccion].id_state);
    this.nombreEstado = estadoSeleccionado || 'S/N Estado';
    console.log(this.direccion[this.radioDireccion]);
    console.log(this.direccion);
    const addressToConfirm = this.direccion[this.radioDireccion].address1
      + ", " + this.direccion[this.radioDireccion].colony
      + ", " + this.direccion[this.radioDireccion].city
      + ", " + this.nombreEstado
      + ", " + "Mexico"
      + ", C.P. " + this.direccion[this.radioDireccion].postcode;

    Swal.fire({
      title: "Favor de validar dirección de envío",
      html: `<p><b>Por favor, asegúrate de ingresar una dirección de envío completa y correcta para evitar cualquier problema en la entrega</b><p></br>
      ${addressToConfirm}`,
      showCancelButton: true,
      confirmButtonText: "Modificar Dirección",
      cancelButtonText: "Continuar",
      customClass: {
        cancelButton: 'btn btn-primary',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/direcciones"])
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.continuarDireccion();
      }
    });
  }


  continuarDireccion() {
    if (this.checarDireccion) {
      this.seleccionaDireccion();
    } else {
      this.calculaPaqueterias();
      this.registraCarritoDireccion();
      const direccionElement = document.getElementById("direccion");
      if (direccionElement) {
        direccionElement.style.display = "none";
      }
      document.getElementById("envio")!.style.display = "flex";
    }

  }

  regresarDireccion() {
    document.getElementById("personal")!.style.display = "block";
    document.getElementById("direccion")!.style.display = "none";
  }

  continuarEnvio() {
    if (this.radioEnvio == false) {
      this.seleccionaEnvio();
    } else {
      this.registraCarritoEnvio();
      if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
        this.getTotal(this.itemsillos);
      } else {
        this.getTotal(this.itemsillos);
      }

      // this.initConfig();
      this.nombreCompleto = this.isInvitado ? this.formInvitado?.nombre || '' : this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        document.getElementById("pago")!.style.display = "flex";
      document.getElementById("envio")!.style.display = "none";
      this.textoCargando = "Cargando Metodos de pago..."
      this.cargando = true;
      this.usuarioService.consultaDatosFactura(this.usuario.usuario).subscribe(datosdeFactura => {
        this.facturitadatos = datosdeFactura;
        if (this.facturitadatos.length > 0) {
          this.usocfdi = this.facturitadatos[0].uso_cfdi;
          this.razonSocial = this.facturitadatos[0].razon_social;
          this.rfc = this.facturitadatos[0].rfc;
          this.codigoPostal = this.facturitadatos[0].cp;
          this.DirFiscal = this.facturitadatos[0].direccion_fiscal;
          this.metodinPago = this.facturitadatos[0].metodo_pago;
          this.regimenfiscal = this.facturitadatos[0].regimen_fiscal;

        }
        if (this.grandTotal !== undefined && this.grandTotal !== null) {
          this.apiFacebook.sendEvent('AddPaymentInfo', this.grandTotal.toFixed(2), this.usuario.usuario, this.direccion[0].phone, {
            external_id: this.customerID,
            nombre: this.direccion[0].firstname,
            apellido: this.direccion[0].lastname,
            estado: this.direccion[0].id_state,
            ciudad: this.direccion[0].city,
            country: 'México',
            codigo_postal: this.direccion[0].postcode
          });
        } else {
          console.warn('grandTotal está indefinido o es nulo');
          // Manejar el caso en que grandTotal es indefinido o nulo
        }
        this.tiktokEvents.sendEvent({ event: 'AddPaymentInfo' })
        this.cargando = false;

      })


    }

  }

  regresarEnvio() {
    document.getElementById("direccion")!.style.display = "flex";
    document.getElementById("envio")!.style.display = "none";
  }

  regresaPago() {
    location.reload();
    document.getElementById("envio")!.style.display = "block";
    document.getElementById("pago")!.style.display = "none";
  }

  checalo() {
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(async data => {
      this.datosUsuario = data;
      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
      this.customerID = this.datosUsuario.customerID;
    })
  }

  enviarEncuesta() {
    const { firstName, lastName } = this.splitFullName(this.formInvitado?.nombre || '');
    if (this.direccion.length > 0 && this.usuario) {
      var datosEncuesta = {
        firstname: this.isInvitado ? firstName : this.direccion[0].firstname,
        lastname: this.isInvitado ? lastName : this.direccion[0].lastname,
        email: this.isInvitado ? this.formInvitado?.email : this.usuario.usuario,
      };
      this.usuarioService.encuestaSatisfaccion(JSON.stringify(datosEncuesta)).subscribe(response => {
        console.log("encusta enviada con exito")
      });
    }
  }

  async tambienChecalo() {

    this.cargando = true;

    await this.clienteSubdistribuidor();
    this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
      try {
        this.direccion = res;

        this.telefonito = this.direccion[0].phone;

        for (let i = 0; i < this.direccion.length; i++) {
          const element = this.direccion[i].id_state;
          if (element == 57) {
            this.nombreEstado = "Aguascalientes"
          }
          if (element == 58) {
            this.nombreEstado = "Baja California"
          }
          if (element == 59) {
            this.nombreEstado = "Baja California Sur"
          }
          if (element == 60) {
            this.nombreEstado = "Campeche"
          }
          if (element == 61) {
            this.nombreEstado = "Chiapas"
          }
          if (element == 62) {
            this.nombreEstado = "Chihuahua"
          }
          if (element == 63) {
            this.nombreEstado = "Coahuila"
          }
          if (element == 64) {
            this.nombreEstado = "Colima"
          }
          if (element == 65) {
            this.nombreEstado = "Distrito Federal"
          }
          if (element == 66) {
            this.nombreEstado = "Durango"
          }
          if (element == 67) {
            this.nombreEstado = "Guanajuato"
          }
          if (element == 68) {
            this.nombreEstado = "Guerrero"
          }
          if (element == 69) {
            this.nombreEstado = "Hidalgo"
          }
          if (element == 70) {
            this.nombreEstado = "Jalisco"
          }
          if (element == 71) {
            this.nombreEstado = "Estado de Mexico"
          }
          if (element == 72) {
            this.nombreEstado = "Michoacan"
          }
          if (element == 73) {
            this.nombreEstado = "Morelos"
          }
          if (element == 74) {
            this.nombreEstado = "Nayarit"
          }
          if (element == 75) {
            this.nombreEstado = "Nuevo Leon"
          }
          if (element == 76) {
            this.nombreEstado = "Oaxaca"
          }
          if (element == 77) {
            this.nombreEstado = "Puebla"
          }
          if (element == 78) {
            this.nombreEstado = "Queretaro"
          }
          if (element == 79) {
            this.nombreEstado = "Quintana Roo"
          }
          if (element == 80) {
            this.nombreEstado = "San Luis Potosi"
          }
          if (element == 81) {
            this.nombreEstado = "Sinaloa"
          }
          if (element == 82) {
            this.nombreEstado = "Sonora"
          }
          if (element == 83) {
            this.nombreEstado = "Tabasco"
          }
          if (element == 84) {
            this.nombreEstado = "Tamaulipas"
          }
          if (element == 85) {
            this.nombreEstado = "Tlaxcala"
          }
          if (element == 86) {
            this.nombreEstado = "Veracruz"
          }
          if (element == 87) {
            this.nombreEstado = "Yucatan"
          }
          if (element == 88) {
            this.nombreEstado = "Zacatecas"
          }

        }
        this.cargando = false;
      } catch (error) {
        this.medioErrorAlv = 1;
        this.cargando = false;
      }
    })


  }

  direccionIsChecked(id_addressssss: any) {

    if (this.facturame == true) {
      var countyJson = {
        "codigo_postal": this.codigoPostal,
        "asentamiento": this.coloniaFactura

      }
    } else {
      var countyJson = {
        "codigo_postal": this.direccion[this.radioDireccion].postcode,
        "asentamiento": this.direccion[this.radioDireccion].colony

      }
    }


    try {
      this.usuarioService.checaCountyID(JSON.stringify(countyJson)).subscribe(countyID => {
        this.datoscounty = countyID;

        if (this.datoscounty && this.datoscounty.length > 0) {
          this.countyId = this.datoscounty[0].CountyId;
        } else {
          console.warn('datoscounty está indefinido o vacío');
          this.countyId = null; // O cualquier valor por defecto que tenga sentido en tu contexto
        }

        if (this.countyId == null || this.countyId == undefined) {
          this.countyId = this.datoscounty[0].id_codigo_postal + "_" + this.direccion[this.radioDireccion].postcode
        }

        this.city = this.getNombreEstado(this.direccion[this.radioDireccion].id_state).toUpperCase()
        if (this.city == "CHIHUAHUA") {
          this.codigo = "CHH"
        }
        if (this.city == "AGUASCALIENTES") {
          this.codigo = "AGS"
        }
        if (this.city == "BAJA CALIFORNIA") {
          this.codigo = "BCA"
        }
        if (this.city == "BAJA CALIFORNIA SUR") {
          this.codigo = "BCS"
        }
        if (this.city == "CAMPECHE") {
          this.codigo = "CAM"
        }
        if (this.city == "CIUDAD DE MEXICO") {
          this.codigo = "CDM"
        }
        if (this.city == "COAHUILA") {
          this.codigo = "COA"
        }
        if (this.city == "COLIMA") {
          this.codigo = "COL"
        }
        if (this.city == "DURANGO") {
          this.codigo = "DGO"
        }
        if (this.city == "DISTRITO FEDERAL") {
          this.codigo = "DIF"
        }
        if (this.city == "GUERRERO") {
          this.codigo = "GRO"
        }
        if (this.city == "GUANAJUATO") {
          this.codigo = "GTO"
        }
        if (this.city == "HIDALGO") {
          this.codigo = "HGO"
        }
        if (this.city == "JALISCO") {
          this.codigo = "JAL"
        }
        if (this.city == "ESTADO DE MEXICO") {
          this.codigo = "MEX"
        }
        if (this.city == "MICHOACAN") {
          this.codigo = "MIC"
        }
        if (this.city == "MORELOS") {
          this.codigo = "MOR"
        }
        if (this.city == "NAYARIT") {
          this.codigo = "NAY"
        }
        if (this.city == "NUEVO LEON") {
          this.codigo = "NLE"
        }
        if (this.city == "OAXACA") {
          this.codigo = "OAX"
        }
        if (this.city == "PUEBLA") {
          this.codigo = "PUE"
        }
        if (this.city == "QUERETARO") {
          this.codigo = "QRO"
        }
        if (this.city == "QUINTANA ROO") {
          this.codigo = "ROO"
        }
        if (this.city == "SINALOA") {
          this.codigo = "SIN"
        }
        if (this.city == "SAN LUIS POTOSI") {
          this.codigo = "SLP"
        }
        if (this.city == "SONORA") {
          this.codigo = "SON"
        }
        if (this.city == "TABASCO") {
          this.codigo = "TAB"
        }
        if (this.city == "TAMAULIPAS") {
          this.codigo = "TAM"
        }
        if (this.city == "TLAXCALA") {
          this.codigo = "TLA"
        }
        if (this.city == "VERACRUZ") {
          this.codigo = "VER"
        }
        if (this.city == "YUCATAN") {
          this.codigo = "YUC"
        }
        if (this.city == "ZACATECAS") {
          this.

            codigo = "ZAC"
        }


      })
    } catch (error) {

    }

    // this.usuarioService.checasihayDyn(this.customerID).subscribe(sihaydir=>{

    // })








    if (this.direccion[this.radioDireccion].city == undefined || this.direccion[this.radioDireccion].city == "") {

      this.faltaInformacionDireccion = true;
      this.checarDireccion = true;

      Swal.fire({
        title: "Ciudad Faltante!",
        text: "La dirección seleccionada no tiene ciudad, favor de ingresar a direcciones",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Corregir"
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/direcciones']);
        }
      });
    }
    if (this.direccion[this.radioDireccion].postcode == undefined || this.direccion[this.radioDireccion].postcode == "") {
      // this.direccionMala();
      this.faltaInformacionDireccion = true;
      this.checarDireccion = true;

      Swal.fire({
        title: "Codigo Postal Faltante!",
        text: "La dirección seleccionada no tiene Codigo Postal, favor de ingresar a direcciones",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Corregir"
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/direcciones']);
        }
      });
    }
    if (this.direccion[this.radioDireccion].id_state == undefined || this.direccion[this.radioDireccion].id_state == "" || this.direccion[this.radioDireccion].id_state == 0) {
      // this.direccionMala();
      this.faltaInformacionDireccion = true;
      this.checarDireccion = true;
      Swal.fire({
        title: "Nombre de Estado Faltante!",
        text: "La dirección seleccionada no tiene Estado, favor de ingresar a direcciones",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Corregir"
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/direcciones']);
        }
      });


    }
    if (this.direccion[this.radioDireccion].address1 == undefined || this.direccion[this.radioDireccion].address1 == "") {
      // this.direccionMala();
      this.faltaInformacionDireccion = true;
      this.checarDireccion = true;

      Swal.fire({
        title: "Calle y Numero Faltante!",
        text: "La dirección seleccionada no tiene Calle y Numero, favor de ingresar a direcciones",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Corregir"
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/direcciones']);
        }
      });
    }
    if (this.direccion[this.radioDireccion].colony == undefined || this.direccion[this.radioDireccion].colony == "") {
      // this.direccionMala();
      this.faltaInformacionDireccion = true;
      this.checarDireccion = true;

      Swal.fire({
        title: "¡Datos Faltantes!",
        text: "La Direccion seleccionada no tiene colonia, porfavor Ingresala.",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ingresar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Ingresa tu colonia",
            input: 'text',
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            showCancelButton: true
          }).then((result) => {
            let jsonCol = {
              colony: result.value,
              id_adress: id_addressssss
            }
            if (result.value) {
              this.usuarioService.actualizaDirColonia(JSON.stringify(jsonCol)).subscribe(sis => {

                location.reload();
              })
            }
          });
        }
      });
    }

    if (this.direccion[this.radioDireccion].colony != undefined && this.direccion[this.radioDireccion].colony != ""
      && this.direccion[this.radioDireccion].city != undefined && this.direccion[this.radioDireccion].city != ""
      && this.direccion[this.radioDireccion].postcode != undefined && this.direccion[this.radioDireccion].postcode != ""
      && this.direccion[this.radioDireccion].id_state != undefined && this.direccion[this.radioDireccion].id_state != ""
      && this.direccion[this.radioDireccion].city != undefined && this.direccion[this.radioDireccion].city != ""
    ) {
      this.faltaInformacionDireccion = false;
      this.checarDireccion = false;
      this.postcode = this.direccion[this.radioDireccion].postcode;
      this.address1 = this.direccion[this.radioDireccion].address1;
      this.id_state = this.direccion[this.radioDireccion].id_state;
      this.city = this.direccion[this.radioDireccion].city;
      this.colony = this.direccion[this.radioDireccion].colony;


    }







  }

  getPeso(data: any) {
    let subs = 0;

    for (const item of data) {
      subs += item.weight * item.quantity;

      this.peso = subs;

    }
  }

  agarrameElTotal(data: any) {
    let subs = 0;

    let jsonCupon = { "email": this.usuario.usuario }
    this.usuarioService.revisaDescuento(jsonCupon).subscribe(codigos => {
      this.datosdelcarro = codigos;

      if (this.datosdelcarro && this.datosdelcarro.length > 0) {
        this.numero_carro = this.datosdelcarro[0].id_cart;
      } else {
        console.warn('datosdelcarro está indefinido o vacío');
        this.numero_carro = null; // O cualquier valor por defecto que tenga sentido en tu contexto
      }


      if (this.datosdelcarro[0].descuento == 1) {
        for (const item of data) {
          subs += item.precio * item.quantity;

          this.porcentaje = this.datosdelcarro[0].porcentaje;

          this.grandTotal = subs;
          this.calculo = this.grandTotal * this.datosdelcarro[0].porcentaje;
          this.grandTotal = this.grandTotal - this.calculo;


        }
      } else {
        for (const item of data) {
          subs += item.precio * item.quantity;

          this.grandTotal = subs;
          this.grandTotal = this.grandTotal + this.totalEnvio;
        }
      }


    })

  }

  getTotal(data: any) {
    let subs = 0;


    if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
      let jsonCupon = { "email": this.usuario.usuario }
      this.usuarioService.revisaDescuento(jsonCupon).subscribe(codigos => {
        this.datosdelcarro = codigos;

        if (this.datosdelcarro && this.datosdelcarro.length > 0) {
          this.numero_carro = this.datosdelcarro[0].id_cart;
        } else {
          console.warn('datosdelcarro está indefinido o vacío');
          this.numero_carro = null; // O cualquier valor por defecto que tenga sentido en tu contexto
        }


        if (this.datosdelcarro[0].descuento == 1) {
          for (const item of data) {
            subs += item.precio * item.quantity;

            this.porcentaje = this.datosdelcarro[0].porcentaje;
            this.porcentajeparalinea = ((100 - (this.porcentaje * 100)) / 100);
            this.grandTotal = subs;
            this.calculo = this.grandTotal * this.datosdelcarro[0].porcentaje;
            this.grandTotal = this.grandTotal - this.calculo;
            this.grandTotal = this.grandTotal + this.totalEnvio;


          }
          this.totalpaypalsito = this.grandTotal.toFixed(2)




        } else {
          for (const item of data) {
            subs += item.precio * item.quantity;

            this.grandTotal = subs;
            this.grandTotal = this.grandTotal + this.totalEnvio;

          }

          if (this.grandTotal !== undefined && this.grandTotal !== null) {
            this.totalpaypalsito = this.grandTotal.toFixed(2);
          } else {
            console.warn('grandTotal está indefinido o es nulo');
          }

          //     let totalPaypal = this.grandTotal.toFixed(2);


          //         (window as any)['paypal'].Buttons({

          //           // Set up the transaction
          //           createOrder: function (data:any, actions:any) {
          //             return actions.order.create({
          //               purchase_units: [{
          //                 amount: {
          //                     currency_code: 'MXN',
          //                     value: (totalPaypal).toString(),

          //                     breakdown: {
          //                         item_total: {
          //                             currency_code: 'MXN',
          //                             value: (totalPaypal).toString(),

          //                       }
          //                     }
          //                 },

          //             }]
          //             });
          //           },


          //           onApprove: function (data:any, actions:any) {
          //             return actions.order.capture()
          //               .then(function (details:any) {

          //                 let compra = document.getElementById('comprame');
          //                 compra!.click();

          //               })
          //               .catch((err:any) => {

          //               })
          //           }
          //         }).render('#paypal-button-container');



        }


      })
    } else {
      for (const item of data) {
        subs += item.precio * item.quantity;


        this.grandTotal = subs;
        this.grandTotal = this.grandTotal + this.totalEnvio;

      }

      this.totalpaypalsito = this.grandTotal.toFixed(2)

    }




  }

  calculaPaqueterias() {

    if (this.peso >= 0.00 && this.peso < 6.00) {
      this.estafeta = 250.00;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 6.00 && this.peso < 8.00) {
      this.estafeta = 250.00;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 8.00 && this.peso < 10.00) {
      this.estafeta = 250.00;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 10.00 && this.peso < 12.00) {
      this.estafeta = 264.77;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 12.00 && this.peso < 14.00) {
      this.estafeta = 289.66;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 14.00 && this.peso < 16.00) {
      this.estafeta = 314.56;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 16.00 && this.peso < 18.00) {
      this.estafeta = 339.45;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 18.00 && this.peso < 20.00) {
      this.estafeta = 364.34;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 20.00 && this.peso < 22.00) {
      this.estafeta = 389.24;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 22.00 && this.peso < 24.00) {
      this.estafeta = 414.13;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 24.00 && this.peso < 26.00) {
      this.estafeta = 439.03;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 26.00 && this.peso < 28.00) {
      this.estafeta = 463.92;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 28.00 && this.peso < 30.00) {
      this.estafeta = 488.81;
      this.paqueteExpress = 191.40;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    if (this.peso >= 30.00 && this.peso < 2000.00) {
      this.estafeta = 488.81;
      this.paqueteExpress = 407.16;
      if (this.grandTotal > 2000) {
        this.estafeta = 0.1;
        this.paqueteExpress = 0.1;
      }
    }
    //console.log('this.paqueteExpress', this.paqueteExpress)


  }


  envioEstafeta() {
    this.recoge = false;
    //this.radioEnvio = true;
    this.tipoEnvio = "Estafeta Estandar"
    this.valorEnvio = 2;
    this.totalEnvio = this.estafeta;
    this.modoEntrega = "PAQUETERIA";
    this.mensajepasa = "";
  }

  envioPaqueteExpress() {
    this.recoge = false;
    //this.radioEnvio = true;
    this.tipoEnvio = "Paquete Express"
    this.valorEnvio = 1;
    this.totalEnvio = this.paqueteExpress;
    //console.log('this.totalEnvio', this.totalEnvio)
    this.modoEntrega = "PAQUETERIA";
    this.mensajepasa = "";
  }

  envioRecoge() {
    this.recoge = true;
    //this.radioEnvio = true;
    this.tipoEnvio = "Recoger en Sucursal"
    this.valorEnvio = 3;
    this.totalEnvio = 0;
    this.modoEntrega = "PASA";
    this.mensajepasa = " ||  Puedes pasar a recoger tu pedido despues de 2 horas de la compra.";
  }

  pagarConMercadoP() {
    const { firstName, lastName } = this.splitFullName(this.formInvitado?.nombre || '');
    if (this.mercadoWasOpen) return


    console.log("Pagar con Mercado")

    this.sepuede = true;
    clearInterval(this.intervalopago);
    this.paymentMethod = "03"; //! Cambiar cuando tenga su propio ID
    this.PaymentTermsName = "CONTADO";
    this.radioPago = false;
    this.metodoPago = "Mercado Pago"
    this.valorRadio = 21;

    //* --- MERCADOPAGO ---
    this.CotMercadoPago().then(() => {
      console.warn("FINALIZO COT ")
      renderPaymentBrick(this.bricksBuilder);
    }).catch(e => {
      console.error(e)
    })
    //* --- MERCADOPAGO ---
    //@ts-expect-error
    const renderPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          /*
            "amount" es el monto total a pagar por todos los medios de pago con excepción de la Cuenta de Mercado Pago y Cuotas sin tarjeta de crédito, las cuales tienen su valor de procesamiento determinado en el backend a través del "preferenceId"
          */
          amount: Number(this.grandTotal.toFixed(2)), //! Importante que este fixeado a 2
          preferenceId: this.MP_preferencePayment,

          payer: {
            firstName: this.isInvitado ? firstName : this.usuarioService.getUserData()?.nombre,
            lastName: this.isInvitado ? lastName : this.usuarioService.getUserData()?.apellido,
            email: this.isInvitado ? this.formInvitado?.email : this.usuarioService.getUserData()?.correo,
          },
        },
        external_reference: this.MP_cotizacionPre,
        externalreference: this.MP_cotizacionPre,
        customization: {
          visual: {
            style: {
              theme: "default",
            },
          },
          paymentMethods: {
            creditCard: "all",
            debitCard: "all",
            ticket: "all",
            bankTransfer: "all",
            atm: "all",
            onboarding_credits: "all",
            //? Aqui se debe descomentar las siguientes lineas para activar metodo de pago: MercadoPago & WalletPurchase
            // wallet_purchase: "all",
            // mercadoPago: 'all',
            maxInstallments: 3
          },
        },
        callbacks: {
          onReady: () => {
            this.mercadoWasOpen = true;
            this.MP_isLoading = false
            /*
             Callback llamado cuando el Brick está listo.
             Aquí puede ocultar cargamentos de su sitio, por ejemplo.
            */
          },
          //@ts-expect-error
          onSubmit: ({ selectedPaymentMethod, formData }) => {
            // callback llamado al hacer clic en el botón de envío de datos
            return new Promise((resolve, reject) => {
              fetch("https://payments.lideart.com.mx/lideart/pagos/mercadoPago", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
              })
                .then((response) => response.json())
                .then((response) => {
                  this.cdr.detectChanges();

                  this.MP_isCompletePayment = true;

                  // this.document.getElementById('paymentBrick_container')?.hidden

                  //* Esto es para renderizar el componente que indica el estado del pago.
                  const renderStatusScreenBrick = async (bricksBuilder: any) => {
                    const settings = {
                      initialization: {
                        paymentId: response.id, // Payment identifier, from which the status will be checked
                      },
                      customization: {
                        visual: {
                          hideStatusDetails: false,
                          hideTransactionDate: false,
                          style: {
                            theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
                          }
                        },
                        backUrls: {
                          'error': 'https://lideart.com.mx/resumen-compra',
                          'return': 'https://lideart.com.mx/resumen-compra'
                        }
                      },
                      callbacks: {
                        //! Esto es una muy mala practica, pero de momento no encontre otra forma de como realizarla.
                        //! Sirve para ocultar el formulario de compra de mercado pago. Esto lo hace refrescado el doom; y es necesario ya que la libreria de 
                        //! mercadolibre inyecta forzosamente el html y lo rompe.
                        onReady: () => {
                          const event = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            code: 'Enter',
                            keyCode: 13,
                            charCode: 13,
                            bubbles: true
                          });

                          document.dispatchEvent(event);

                        },
                        onError: (error: any) => {
                          console.error(error)
                        },
                      },
                    };
                    //@ts-expect-error
                    window.statusScreenBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
                  };
                  renderStatusScreenBrick(this.bricksBuilder);
                  this.relizarCompra(); -
                    console.log({ response })
                  resolve(response);
                })
                .catch((error) => {
                  console.log({ error })

                  // alert('error')
                  // manejar la respuesta de error al intentar crear el pago
                  reject();
                });
            });
          },

          onready: () => {
            console.log('Ready')
          },
          //@ts-expect-error
          onError: (error) => {
            // callback llamado para todos los casos de error de Brick
            console.error(error);
          },
        },
      };
      //@ts-expect-error 
      window.paymentBrickController = await bricksBuilder.create(
        "payment",
        "paymentBrick_container",
        settings
      );
    };
  }


  pagarConKueski() {
    console.log("Pagar con kueski")

    this.sepuede = true;
    clearInterval(this.intervalopago);
    this.paymentMethod = "03"; //! Cambiar cuando tenga su propio ID
    this.PaymentTermsName = "CONTADO";
    this.radioPago = false;
    this.metodoPago = "Kueski Pay"
    this.valorRadio = 5;
  }

  transferencia() {
    console.log("Pagar con transfer")

    //console.log(1.1)
    clearInterval(this.intervalopago);
    //console.log(1.2)
    this.sepuede = true;
    //console.log(1.3)
    // document.getElementById('comprame').style.display = "block";

    this.valorRadio = 1;
    this.radioPago = false;
    this.metodoPago = "Transferencia"
    this.paymentMethod = "03";
    this.PaymentTermsName = "CONTADO";
    //console.log(1.4)
    // this.paypal();
  }



  oxxoPay() {
    this.sepuede = true;
    clearInterval(this.intervalopago);
    // document.getElementById('comprame').style.display = "block";
    this.paymentMethod = "28";
    this.PaymentTermsName = "CONTADO";
    this.radioPago = false;
    this.metodoPago = "Oxxo Pay"
    this.valorRadio = 2;
    // this.setOpen3(true);

  }


  paypal() {

    clearInterval(this.intervalopago);
    // document.getElementById('comprame').style.display = "none";
    this.sepuede = false;
    this.radioPago = false;
    this.valorRadio = 4;
    this.metodoPago = "PayPal";
    this.PaymentTermsName = "CONTADO";
    this.paymentMethod = "03";
    // document.getElementById('open-modal10')!.click();

    // document.getElementById('modalsito10')!.style.display = "flex";

    // if (this.paypalIs == false) {
    //   this.renderer.setStyle(this.paypalcont.nativeElement, 'display', 'block');
    //   this.paypalIs = true;
    // }else if (this.paypalIs == true) {
    //   this.renderer.setStyle(this.paypalcont.nativeElement, 'display', 'none');
    //   this.paypalIs = false;
    // }

  }

  async facturameprro() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Debes aceptar los terminos y condiciones de pago',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // //console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  async CotMercadoPago() {
    const lin = []

    for (let item of this.itemsillos) {

      if (item.paquete == 1) {

        this.productoService.getInfoPaquetes(item.sku).subscribe(paquetaxo => {
          this.paquetaxos = paquetaxo;

          if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
            if (this.datosdelcarro[0].descuento == 1) {
              for (let index = 0; index < this.paquetaxos.length; index++) {
                let lineasLocal = {
                  salesQuotationNumber: null,
                  dataAreaId: "lin",
                  itemNumber: this.paquetaxos[index].sku,
                  requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                  shippingWarehouseId: "CHIHCONS",
                  fixedPriceCharges: 0,
                  salesTaxGroupCode: "VTAS",
                  stF_Category: null,
                  inventoryLotId: null,
                  orderedSalesQuantity: 0,
                  salesOrderNumber: null,
                  descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                  usuario: null,
                  salesPrice: Number(this.paquetaxos[index].precio_total) * this.porcentajeparalinea,
                  ShippingSiteId: "CHIH",
                  LineDiscountPercentage: 0
                };
                lin.push(lineasLocal);
              }
            }
          }
          else {
            for (let index = 0; index < this.paquetaxos.length; index++) {
              let lineasLocal = {
                salesQuotationNumber: null,
                dataAreaId: "lin",
                itemNumber: this.paquetaxos[index].sku,
                requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                shippingWarehouseId: "CHIHCONS",
                fixedPriceCharges: 0,
                salesTaxGroupCode: "VTAS",
                stF_Category: null,
                inventoryLotId: null,
                orderedSalesQuantity: 0,
                salesOrderNumber: null,
                descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                usuario: null,
                salesPrice: Number(this.paquetaxos[index].precio_total),
                ShippingSiteId: "CHIH",
                LineDiscountPercentage: 0
              };
              lin.push(lineasLocal);
            }
          }
        })
      }

      if (item.paquete != 1) {

        if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
          if (this.datosdelcarro[0].descuento == 1) {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: item.sku,
              requestedSalesQuantity: Number(item.quantity),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title.replace(/"/, ""),
              usuario: null,
              salesPrice: Number(item.precio / 1.16) * this.porcentajeparalinea,
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };

            lin.push(lineasLocal);
          }
        }

        else {
          let lineasLocal = {
            salesQuotationNumber: null,
            dataAreaId: "lin",
            itemNumber: item.sku,
            requestedSalesQuantity: Number(item.quantity),
            shippingWarehouseId: "CHIHCONS",
            fixedPriceCharges: 0,
            salesTaxGroupCode: "VTAS",
            stF_Category: null,
            inventoryLotId: null,
            orderedSalesQuantity: 0,
            salesOrderNumber: null,
            descripcionLinea: item.title.replace(/"/, ""),
            usuario: null,
            salesPrice: Number(item.precio / 1.16),
            ShippingSiteId: "CHIH",
            LineDiscountPercentage: 0
          };

          lin.push(lineasLocal);
        }

      }
    }
    //console.log(4)

    if (this.recoge == false && this.valorEnvio == 2) {
      let lineasPaqueteria = {
        salesQuotationNumber: null,
        dataAreaId: "lin",
        itemNumber: '9999-0850',
        requestedSalesQuantity: 1,
        shippingWarehouseId: "CHIHCONS",
        fixedPriceCharges: 0,
        salesTaxGroupCode: "VTAS",
        stF_Category: null,
        inventoryLotId: null,
        orderedSalesQuantity: 0,
        salesOrderNumber: null,
        descripcionLinea: "GUIA VIRTUAL FLETE TERRESTRE ESTAFETA 5 KILOS",
        usuario: null,
        salesPrice: Number(this.estafeta),
        ShippingSiteId: "CHIH",
        LineDiscountPercentage: 0
      };
      lin.push(lineasPaqueteria);
    }

    //console.log(5)

    if (this.recoge == false && this.valorEnvio == 1) {
      let lineasPaqueteria = {
        salesQuotationNumber: null,
        dataAreaId: "lin",
        itemNumber: '9999-9003',
        requestedSalesQuantity: 1,
        shippingWarehouseId: "CHIHCONS",
        fixedPriceCharges: 0,
        salesTaxGroupCode: "VTAS",
        stF_Category: null,
        inventoryLotId: null,
        orderedSalesQuantity: 0,
        salesOrderNumber: null,
        descripcionLinea: "SERVICIO PAQUETE EXPRESS",
        usuario: null,
        salesPrice: Number(this.paqueteExpress),
        ShippingSiteId: "CHIH",
        LineDiscountPercentage: 0

      };
      lin.push(lineasPaqueteria);
    }

    if (this.facturame == true) {
      this.objetoCompra = {

        salesQuotationNumber: null,
        currencyCode: "MXN",
        languageId: "es-MX",
        dataAreaId: "lin",
        defaultShippingSiteId: "CHIH",
        requestingCustomerAccountNumber: this.customerID,
        quotationTakerPersonnelNumber: "0003028",
        quotationResponsiblePersonnelNumber: "0003028",
        salesOrderOriginCode: "MOTV_00032",
        salesTaxGroupCode: "VTAS",
        deliveryModeCode: this.modoEntrega,
        customersReference: "",
        customerPaymentMethodName: this.paymentMethod,
        paymentTermsName: this.PaymentTermsName,
        usuario: this.usuario.usuario,
        promoCode: null,
        nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        formattedInvoiceAddress: this.DirFiscal + "\n" + this.ciudadFactura + "," + this.codigo + ",MEX\n" + this.codigoPostal,
        deliveryAddressStreet: this.DirFiscal,
        deliveryAddressCountryRegionId: "MEX",
        deliveryAddressCity: this.ciudadFactura,
        deliveryAddressCountyId: this.countyId,
        deliveryAddressZipCode: this.codigoPostal,
        deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        deliveryAddressStateId: this.codigo,
        enviarCorreo: false,
        correoCliente: this.usuario.usuario,
        nombreEmpleado: "Eduardo Acosta",
        correoEmpleado: "sistemas16@avanceytec.com.mx",
        DeliveryAddressCountyId: this.countyId,
        DeliveryAddressLocationId: "000537244",
        DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        DefaultShippingWarehouseId: "CHIHCONS",
        Lineas: this.arreglito
      }

    } else if (this.usuario.usuario == "bemixom200@bongcs.com") {
      //console.log("Si entro a prueba");
      this.objetoCompra = {

        salesQuotationNumber: null,
        currencyCode: "MXN",
        languageId: "es-MX",
        dataAreaId: "lin",
        defaultShippingSiteId: "CHIH",
        requestingCustomerAccountNumber: "CL0001348",
        quotationTakerPersonnelNumber: "0003028",
        quotationResponsiblePersonnelNumber: "0003028",
        salesOrderOriginCode: "MOTV_00049",
        salesTaxGroupCode: "VTAS",
        deliveryModeCode: this.modoEntrega,
        customersReference: "",
        customerPaymentMethodName: this.paymentMethod,
        paymentTermsName: this.PaymentTermsName,
        usuario: this.usuario.usuario,
        promoCode: null,
        nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        formattedInvoiceAddress: this.direccion[this.radioDireccion].address1 + "\n" + this.direccion[this.radioDireccion].city + "," + this.codigo + ",MEX\n" + this.direccion[this.radioDireccion].postcode,
        deliveryAddressStreet: this.address1,
        deliveryAddressCountryRegionId: "MEX",
        deliveryAddressCity: this.city,
        deliveryAddressCountyId: this.countyId,
        deliveryAddressZipCode: this.postcode,
        deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        deliveryAddressStateId: this.codigo,
        enviarCorreo: false,
        correoCliente: this.usuario.usuario,
        nombreEmpleado: "Eduardo Acosta",
        correoEmpleado: "sistemas16@avanceytec.com.mx",
        DeliveryAddressCountyId: this.countyId,
        DeliveryAddressLocationId: "000537244",
        DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        DefaultShippingWarehouseId: "CHIHCONS",
        Lineas: this.arreglito
      }
    } else {
      this.objetoCompra = {

        salesQuotationNumber: null,
        currencyCode: "MXN",
        languageId: "es-MX",
        dataAreaId: "lin",
        defaultShippingSiteId: "CHIH",
        requestingCustomerAccountNumber: "CL0001348",
        quotationTakerPersonnelNumber: "0003028",
        quotationResponsiblePersonnelNumber: "0003028",
        salesOrderOriginCode: "MOTV_00032",
        salesTaxGroupCode: "VTAS",
        deliveryModeCode: this.modoEntrega,
        customersReference: "",
        customerPaymentMethodName: this.paymentMethod,
        paymentTermsName: this.PaymentTermsName,
        usuario: this.usuario.usuario,
        promoCode: null,
        nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        formattedInvoiceAddress: this.direccion[this.radioDireccion].address1 + "\n" + this.direccion[this.radioDireccion].city + "," + this.codigo + ",MEX\n" + this.direccion[this.radioDireccion].postcode,
        deliveryAddressStreet: this.address1,
        deliveryAddressCountryRegionId: "MEX",
        deliveryAddressCity: this.city,
        deliveryAddressCountyId: this.countyId,
        deliveryAddressZipCode: this.postcode,
        deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        deliveryAddressStateId: this.codigo,
        enviarCorreo: false,
        correoCliente: this.usuario.usuario,
        nombreEmpleado: "Eduardo Acosta",
        correoEmpleado: "sistemas16@avanceytec.com.mx",
        DeliveryAddressCountyId: this.countyId,
        DeliveryAddressLocationId: "000537244",
        DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
        DefaultShippingWarehouseId: "CHIHCONS",
        Lineas: lin
      }
    }

    this.textoCargando = "Creando tu Orden de Venta..."
    const response = await this.checkoutService.addCotizacionDynamics(this.objetoCompra).toPromise()
    const resp = response as any
    //! Recomentar
    // if (resp.data !== null) {
    //   this.MP_cotizacionPre = resp.data.salesQuotationNumber

    //   let r = await this.http.post<{ id: string }>('https://test.payments.lideart.com.mx/lideart/pagos/TMPMercadoPagoPreference', {
    //     ov: this.MP_cotizacionPre,
    //     productos: JSON.parse(localStorage.getItem('cart') || '[]')
    //   }).toPromise()

    //   r = r as { id: string }
    //   this.MP_preferencePayment = r.id
    //   console.table([{ cot: this.MP_cotizacionPre, preferenceId: this.MP_preferencePayment }])
    // }
    // else {

    // }

  }

  relizarCompra() {

    if (this.respuestaOV !== null) this.router.navigate(['/resumen-compra'])

    // this.transferencia();
    //console.log("paso funcion transferencia");


    if (this.facturame2 == false) {
      //console.log("no debe entrar aqui")

      this.facturameprro();
    } else {

      //console.log(2)
      this.textoCargando = "Inicializando tu compra..."
      this.cargando = true;

      //console.log(3)

      for (let item of this.itemsillos) {

        if (item.paquete == 1) {

          this.productoService.getInfoPaquetes(item.sku).subscribe(paquetaxo => {
            this.paquetaxos = paquetaxo;

            if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {



              if (this.datosdelcarro[0].descuento == 1) {
                for (let index = 0; index < this.paquetaxos.length; index++) {
                  let lineasLocal = {
                    salesQuotationNumber: null,
                    dataAreaId: "lin",
                    itemNumber: this.paquetaxos[index].sku,
                    requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                    shippingWarehouseId: "CHIHCONS",
                    fixedPriceCharges: 0,
                    salesTaxGroupCode: "VTAS",
                    stF_Category: null,
                    inventoryLotId: null,
                    orderedSalesQuantity: 0,
                    salesOrderNumber: null,
                    descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                    usuario: null,
                    salesPrice: Number(this.paquetaxos[index].precio_total) * this.porcentajeparalinea,
                    ShippingSiteId: "CHIH",
                    LineDiscountPercentage: 0
                  };
                  this.lineas.push(lineasLocal);
                }
              }
              else {
                for (let index = 0; index < this.paquetaxos.length; index++) {
                  let lineasLocal = {
                    salesQuotationNumber: null,
                    dataAreaId: "lin",
                    itemNumber: this.paquetaxos[index].sku,
                    requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                    shippingWarehouseId: "CHIHCONS",
                    fixedPriceCharges: 0,
                    salesTaxGroupCode: "VTAS",
                    stF_Category: null,
                    inventoryLotId: null,
                    orderedSalesQuantity: 0,
                    salesOrderNumber: null,
                    descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                    usuario: null,
                    salesPrice: Number(this.paquetaxos[index].precio_total),
                    ShippingSiteId: "CHIH",
                    LineDiscountPercentage: 0
                  };
                  this.lineas.push(lineasLocal);
                }
              }
            } else {
              for (let index = 0; index < this.paquetaxos.length; index++) {
                let lineasLocal = {
                  salesQuotationNumber: null,
                  dataAreaId: "lin",
                  itemNumber: this.paquetaxos[index].sku,
                  requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
                  shippingWarehouseId: "CHIHCONS",
                  fixedPriceCharges: 0,
                  salesTaxGroupCode: "VTAS",
                  stF_Category: null,
                  inventoryLotId: null,
                  orderedSalesQuantity: 0,
                  salesOrderNumber: null,
                  descripcionLinea: this.paquetaxos[index].nombre.replace(/"/, ""),
                  usuario: null,
                  salesPrice: Number(this.paquetaxos[index].precio_total),
                  ShippingSiteId: "CHIH",
                  LineDiscountPercentage: 0
                };
                this.lineas.push(lineasLocal);
              }
            }


          })
        }

        if (item.paquete != 1) {

          if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
            if (this.datosdelcarro[0].descuento == 1) {
              let lineasLocal = {
                salesQuotationNumber: null,
                dataAreaId: "lin",
                itemNumber: item.sku,
                requestedSalesQuantity: Number(item.quantity),
                shippingWarehouseId: "CHIHCONS",
                fixedPriceCharges: 0,
                salesTaxGroupCode: "VTAS",
                stF_Category: null,
                inventoryLotId: null,
                orderedSalesQuantity: 0,
                salesOrderNumber: null,
                descripcionLinea: item.title.replace(/"/, ""),
                usuario: null,
                salesPrice: Number(item.precio / 1.16) * this.porcentajeparalinea,
                ShippingSiteId: "CHIH",
                LineDiscountPercentage: 0
              };

              this.lineas.push(lineasLocal);
            } else {
              let lineasLocal = {
                salesQuotationNumber: null,
                dataAreaId: "lin",
                itemNumber: item.sku,
                requestedSalesQuantity: Number(item.quantity),
                shippingWarehouseId: "CHIHCONS",
                fixedPriceCharges: 0,
                salesTaxGroupCode: "VTAS",
                stF_Category: null,
                inventoryLotId: null,
                orderedSalesQuantity: 0,
                salesOrderNumber: null,
                descripcionLinea: item.title.replace(/"/, ""),
                usuario: null,
                salesPrice: Number(item.precio / 1.16),
                ShippingSiteId: "CHIH",
                LineDiscountPercentage: 0
              };

              this.lineas.push(lineasLocal);
            }

          } else {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: item.sku,
              requestedSalesQuantity: Number(item.quantity),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title.replace(/"/, ""),
              usuario: null,
              salesPrice: Number(item.precio / 1.16),
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };

            this.lineas.push(lineasLocal);
          }



        }
      }
      //console.log(4)

      if (this.recoge == false && this.valorEnvio == 2) {
        let lineasPaqueteria = {
          salesQuotationNumber: null,
          dataAreaId: "lin",
          itemNumber: '9999-0850',
          requestedSalesQuantity: 1,
          shippingWarehouseId: "CHIHCONS",
          fixedPriceCharges: 0,
          salesTaxGroupCode: "VTAS",
          stF_Category: null,
          inventoryLotId: null,
          orderedSalesQuantity: 0,
          salesOrderNumber: null,
          descripcionLinea: "GUIA VIRTUAL FLETE TERRESTRE ESTAFETA 5 KILOS",
          usuario: null,
          salesPrice: Number(this.estafeta),
          ShippingSiteId: "CHIH",
          LineDiscountPercentage: 0
        };
        this.lineas.push(lineasPaqueteria);
      }

      //console.log(5)

      if (this.recoge == false && this.valorEnvio == 1) {
        let lineasPaqueteria = {
          salesQuotationNumber: null,
          dataAreaId: "lin",
          itemNumber: '9999-9003',
          requestedSalesQuantity: 1,
          shippingWarehouseId: "CHIHCONS",
          fixedPriceCharges: 0,
          salesTaxGroupCode: "VTAS",
          stF_Category: null,
          inventoryLotId: null,
          orderedSalesQuantity: 0,
          salesOrderNumber: null,
          descripcionLinea: "SERVICIO PAQUETE EXPRESS",
          usuario: null,
          salesPrice: Number(this.paqueteExpress),
          ShippingSiteId: "CHIH",
          LineDiscountPercentage: 0

        };
        this.lineas.push(lineasPaqueteria);
      }

      //console.log(6)

      this.clienteSubdistribuidor();
      this.arreglito = this.lineas;

      setTimeout(() => {

        if (this.facturame == true) {
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: this.customerID,
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: this.MOT_VENTA,
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.DirFiscal + "\n" + this.ciudadFactura + "," + this.codigo + ",MEX\n" + this.codigoPostal,
            deliveryAddressStreet: this.DirFiscal,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.ciudadFactura,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.codigoPostal,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }

        } else if (this.subdistribuidor === 1) {
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: this.customerID,
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: this.MOT_VENTA,
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.DirFiscal + "\n" + this.ciudadFactura + "," + this.codigo + ",MEX\n" + this.codigoPostal,
            deliveryAddressStreet: this.DirFiscal,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.ciudadFactura,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.codigoPostal,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }
        } else if (this.usuario.usuario == "bemixom200@bongcs.com") {
          //console.log("Si entro a prueba");
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: "CL0001348",
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: "MOTV_00049",
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.direccion[this.radioDireccion].address1 + "\n" + this.direccion[this.radioDireccion].city + "," + this.codigo + ",MEX\n" + this.direccion[this.radioDireccion].postcode,
            deliveryAddressStreet: this.address1,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.city,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.postcode,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }
        } else {
          this.objetoCompra = {

            salesQuotationNumber: null,
            currencyCode: "MXN",
            languageId: "es-MX",
            dataAreaId: "lin",
            defaultShippingSiteId: "CHIH",
            requestingCustomerAccountNumber: "CL0001348",
            quotationTakerPersonnelNumber: "0003028",
            quotationResponsiblePersonnelNumber: "0003028",
            salesOrderOriginCode: this.MOT_VENTA,
            salesTaxGroupCode: "VTAS",
            deliveryModeCode: this.modoEntrega,
            customersReference: "",
            customerPaymentMethodName: this.paymentMethod,
            paymentTermsName: this.PaymentTermsName,
            usuario: this.usuario.usuario,
            promoCode: null,
            nombreCliente: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            formattedInvoiceAddress: this.direccion[this.radioDireccion].address1 + "\n" + this.direccion[this.radioDireccion].city + "," + this.codigo + ",MEX\n" + this.direccion[this.radioDireccion].postcode,
            deliveryAddressStreet: this.address1,
            deliveryAddressCountryRegionId: "MEX",
            deliveryAddressCity: this.city,
            deliveryAddressCountyId: this.countyId,
            deliveryAddressZipCode: this.postcode,
            deliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            deliveryAddressStateId: this.codigo,
            enviarCorreo: false,
            correoCliente: this.usuario.usuario,
            nombreEmpleado: "Eduardo Acosta",
            correoEmpleado: "sistemas16@avanceytec.com.mx",
            DeliveryAddressCountyId: this.countyId,
            DeliveryAddressLocationId: "000537244",
            DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
            DefaultShippingWarehouseId: "CHIHCONS",
            Lineas: this.arreglito
          }
        }



        this.checkoutService.guardaObjetoCot(JSON.stringify(this.objetoCompra), this.usuario.usuario).subscribe(guarda => {

        })

        this.textoCargando = "Creando tu Orden de Venta..."
        this.checkoutService.addCotizacionDynamics(this.objetoCompra).subscribe(async (response) => {

          this.cotDatos = response;

          this.respuestaCotizacion = this.cotDatos.data.salesQuotationNumber;
          // this.lineas = this.cotDatos.lineas;

          //TODO: Actualiza la cotizacion de la venta
          await firstValueFrom(this.http.put(`${NEWAPI_BASE_URL}/api/NewLideartVentas/${this._idCompra}`, {
            cotizacion: this.respuestaCotizacion,
            metodo_pago: this.metodoPago,
          }))



          if (this.facturame == false) {
            this.objetoOrdenVenta = {
              DeliveryAddressCity: this.direccion[this.radioDireccion].city,
              DeliveryAddressCountryRegionId: "MEX",
              DeliveryAddressCountyId: this.countyId,
              DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressStateId: this.codigo,
              DeliveryAddressStreet: this.direccion[this.radioDireccion].address1,
              DeliveryAddressStreetNumber: "380",
              DeliveryAddressZipCode: this.direccion[this.radioDireccion].postcode,
              lineas: this.lineas,
              PaymentTermsName: this.PaymentTermsName,
              SATPurpose: this.usocfdi,
              Usuario: "ventascat22",
              dataAreaId: "LIN",
              quotationId: this.MP_cotizacionPre !== '' ? this.MP_cotizacionPre : this.respuestaCotizacion,
              _AccountNum: "CL0001348",
            }
          } else if (this.subdistribuidor === 1) {
            this.objetoOrdenVenta = {
              DeliveryAddressCity: this.direccion[this.radioDireccion].city,
              DeliveryAddressCountryRegionId: "MEX",
              DeliveryAddressCountyId: this.countyId,
              DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressStateId: this.codigo,
              DeliveryAddressStreet: this.direccion[this.radioDireccion].address1,
              DeliveryAddressStreetNumber: "380",
              DeliveryAddressZipCode: this.direccion[this.radioDireccion].postcode,
              lineas: this.lineas,
              PaymentTermsName: this.PaymentTermsName,
              SATPurpose: this.usocfdi,
              Usuario: "ventascat22",
              dataAreaId: "LIN",
              quotationId: this.MP_cotizacionPre !== '' ? this.MP_cotizacionPre : this.respuestaCotizacion,
              _AccountNum: this.customerID,
            }
          } else {
            this.objetoOrdenVenta = {
              DeliveryAddressCity: this.direccion[this.radioDireccion].city,
              DeliveryAddressCountryRegionId: "MEX",
              DeliveryAddressCountyId: this.countyId,
              DeliveryAddressDescription: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressName: this.nombre.toUpperCase() + " " + this.apellido.toUpperCase(),
              DeliveryAddressStateId: this.codigo,
              DeliveryAddressStreet: this.direccion[this.radioDireccion].address1,
              DeliveryAddressStreetNumber: "380",
              DeliveryAddressZipCode: this.direccion[this.radioDireccion].postcode,
              lineas: this.lineas,
              PaymentTermsName: this.PaymentTermsName,
              SATPurpose: this.usocfdi,
              Usuario: "ventascat22",
              dataAreaId: "LIN",
              quotationId: this.MP_cotizacionPre !== '' ? this.MP_cotizacionPre : this.respuestaCotizacion,
              _AccountNum: this.customerID,
            }
          }
          if (this.usuario.usuario != 'dptosistemasavance@gmail.com') {
            this.checkoutService.actualizaNuevoCarrito(this.usuario.usuario).subscribe(respuesta => {

            })
          }



          try {
            this.checkoutService.convertirOrdenVenta(this.objetoOrdenVenta).subscribe(async (respuestilla) => {



              if (this.recoge == false && this.valorEnvio == 1) {

                this.jsonOrigen = {
                  "addrLin1": "MEXICO",
                  "addrLin3": "Chihuahua",
                  "addrLin4": "Chihuahua",
                  "addrLin5": "Chihuahua",
                  "addrLin6": "Parque Industrial Las Americas",
                  "strtName": "C. Washington",
                  "drnr": "3701",
                  "phno1": "(614) 6051278",
                  "zipCode": "31114",
                  "clntName": "Lideart Innovación",
                  "email": "contacto@lideart.com.mx",
                  "contacto": "Lideart Innovación",
                  "addrType": "ORIGIN"
                }


                for (let item of this.itemsillos) {
                  let lineasLocales =
                  {
                    "srvcId": "PACKETS",
                    "weight": "30",
                    "volL": item.alto,
                    "volW": item.largo,
                    "volH": item.height,
                    "cont": item.title,
                    "qunt": item.quantity.toString()
                  }
                  this.lineasProductosPaqueterias.push(lineasLocales);
                }

                let jsonEtiquetas = {
                  "header": {
                    "security": {
                      "user": "WSLIDEARTINNO",
                      "password": "1234",
                      "type": 1,
                      "token": "1EB7968E3E650ECEE063C0A80A147689"
                    }
                  },
                  "body": {
                    "request": {
                      "data": [
                        {
                          "billRad": "REQUEST",
                          "billClntId": "11767387",
                          "pymtMode": "PAID",
                          "pymtType": "C",
                          // "comt":"CON ATN PARA JUAN PEREZ", 
                          "radGuiaAddrDTOList": [
                            this.jsonOrigen,

                            {
                              "addrLin1": "México",
                              "addrLin3": this.isInvitado ? this.formInvitado?.estado : this.getNombreEstado(this.direccion[this.radioDireccion].id_state),
                              "addrLin4": this.isInvitado ? this.formInvitado?.ciudad : this.city,
                              "addrLin5": this.isInvitado ? this.formInvitado?.ciudad : this.city,
                              "addrLin6": this.isInvitado ? this.formInvitado?.colonia : this.colony,
                              "strtName": this.isInvitado ? this.formInvitado?.direccion : this.address1,
                              "drnr": "n/a",
                              "phno1": this.isInvitado ? this.formInvitado?.phone : this.telefonito,
                              "zipCode": this.isInvitado ? this.formInvitado?.cp : this.postcode,
                              "clntName": this.nombreCompleto,
                              "email": this.isInvitado ? this.formInvitado?.email : this.usuario.usuario,
                              "contacto": this.nombreCompleto,
                              "addrType": "DESTINATION"
                            }
                          ],
                          "radSrvcItemDTOList":
                            this.lineasProductosPaqueterias,

                          "listSrvcItemDTO": [
                            {
                              "srvcId": "EAD",
                              "value1": ""
                            },
                            {
                              "srvcId": "RAD",
                              "value1": ""
                            }
                          ],
                          "typeSrvcId": "STD-T",
                          "listRefs": [
                            {
                              "grGuiaRefr": "ARPEG562"
                            }
                          ]
                        }
                      ],
                      "objectDTO": null
                    },
                    "response": null
                  }
                }

                if (this.grandTotal > 2000) {
                  this.checkoutService.generarEtiquetas(jsonEtiquetas).subscribe(async (datosEtiquetas) => {
                    this.etiquetas = datosEtiquetas;
                    this.arrayEtiquetas = this.etiquetas.body.response.data;
                    this.numeroDeEtiquetas.push(this.arrayEtiquetas)

                    await firstValueFrom(this.http.put(`${NEWAPI_BASE_URL}/api/NewLideartVentas/${this._idCompra}`, {
                      arregloEtiquetas: JSON.stringify(this.arrayEtiquetas),
                      metodo_pago: this.metodoPago
                    }))
                  })
                }





              }






              try {
                this.respuestillaOV = respuestilla

                this.respuestaOV = this.respuestillaOV.data.salesOrderNumber;
              } catch (error) {
                this.respuestillaOV = ""
                this.respuestaOV = ""
              }

              await firstValueFrom(this.http.put(`${NEWAPI_BASE_URL}/api/NewLideartVentas/${this._idCompra}`, {
                ov: this.respuestaOV
              }))





              if (this.metodoPago == 'Transferencia') {
                this.jsonMensaje = {
                  "nombreCliente": this.nombre + " " + this.apellido,
                  "noCliente": this.customerID,
                  "folio": this.respuestaOV,
                  "importe": (this.grandTotal + this.totalEnvio).toFixed(2),
                  "correoCliente": this.usuario.usuario,
                  "direccion": this.address1,
                  "CFDI": this.usocfdi,
                  "razonSocial": this.razonSocial,
                  "rfc": this.rfc,
                  "DirFiscal": this.DirFiscal,
                  "FormadePago": this.metodoPago,
                  "Cuenta": this.noCuenta,
                  "Clabe": this.clabe,
                  "Banco": this.banco,
                  "TelPrincipal": "6146051278",
                  "TelComprador": "6143609156"
                }
              } else {
                this.jsonMensaje = {
                  "nombreCliente": this.nombre + " " + this.apellido,
                  "noCliente": this.customerID,
                  "folio": this.respuestaOV,
                  "importe": (this.grandTotal + this.totalEnvio).toFixed(2),
                  "correoCliente": this.usuario.usuario,
                  "direccion": this.address1,
                  "CFDI": this.usocfdi,
                  "razonSocial": this.razonSocial,
                  "rfc": this.rfc,
                  "DirFiscal": this.DirFiscal,
                  "FormadePago": this.metodoPago,
                  "TelPrincipal": "6146051278",
                  "TelComprador": "6143609156"
                }

                var jsonCupon = { "email": this.usuario.usuario, "total": this.grandTotal.toFixed(2) };
                this.productoService.insertaLosPuntos(jsonCupon).subscribe(codigos => {

                })
              }


              // formatDate(this.fechaActual2,'YYYY-MM-DD','');

              //* Hotfix: Esto evita que en caso de multiples clics, se dupliquen los productos en la orden de venta
              if (this.itemsillos.length !== this.lineasBomnitas.length) {

                for (let item of this.itemsillos) {

                  let lineasBomnitas = {
                    nombreProducto: item.title,
                    salesQuotationNumber: null,
                    dataAreaId: "lin",
                    itemNumber: item.sku,
                    requestedSalesQuantity: Number(item.quantity),
                    shippingWarehouseId: "CHIHCONS",
                    fixedPriceCharges: 0,
                    salesTaxGroupCode: "VTAS",
                    stF_Category: null,
                    inventoryLotId: null,
                    orderedSalesQuantity: 0,
                    salesOrderNumber: null,
                    descripcionLinea: item.title,
                    usuario: null,
                    salesPrice: Number(item.precio),
                    ShippingSiteId: "CHIH",
                    LineDiscountPercentage: 0
                  };

                  this.lineasBomnitas.push(lineasBomnitas);

                }
              }

              let html = "<p>Pais: Mexico</p>"
                + "<p>Estado: " + (this.isInvitado ? this.formInvitado?.estado || 'Invitado pasa' : this.getNombreEstado(this.direccion[this.radioDireccion].id_state)) + "</p>"
                + "<p>Ciudad: " + (this.isInvitado ? this.formInvitado?.ciudad || 'Invitado pasa' : this.direccion[this.radioDireccion].city) + "</p>"
                + "<p>Colonia: " + (this.isInvitado ? this.formInvitado?.colonia || 'Invitado pasa' : this.direccion[this.radioDireccion].colony) + "</p>"
                + "<p>CP: " + (this.isInvitado ? this.formInvitado?.cp || 'Invitado pasa' : this.direccion[this.radioDireccion].postcode) + "</p>"
                + "<p>Direccion: " + (this.isInvitado ? this.formInvitado?.direccion || 'Invitado pasa' : this.direccion[this.radioDireccion].address1) + "</p>";


              if (this.facturame == true) {
                this.htmlfactura = "<p>Pais: Mexico</p>"
                  + "<p>Estado: " + this.estadoFactura + "</p>"
                  + "<p>Ciudad: " + this.ciudadFactura + "</p>"
                  + "<p>Colonia: " + this.coloniaFactura + "</p>"
                  + "<p>CP: " + this.codigoPostal + "</p>"
                  + "<p>Direccion: " + this.DirFiscal + "</p>";
              } else {
                this.htmlfactura == "";
              }




              if (this.metodoPago == "Transferencia") {
                this.mensajazo = {

                  "customerName": this.nombreCompleto,
                  "order_name": this.respuestaOV,
                  "date": this.formattedDate,
                  "payment": this.metodoPago + " || Datos de Deposito, CLABE: " + this.clabe + ", Banco :" + this.banco + ", Cuenta: " + this.noCuenta,
                  "products":
                    this.lineasBomnitas
                  ,
                  "discounts": [
                    Number(this.calculo)
                  ],
                  "name_discount": this.codiguito,
                  "total_products": this.grandTotal,
                  "total_discounts": Number(this.calculo),
                  "total_wrapping": 0,
                  "total_shipping": this.totalEnvio,
                  "total_tax_paid": this.grandTotal / 1.16,
                  "total_paid": this.grandTotal,
                  "carrier": this.modoEntrega + " " + this.mensajepasa,
                  "delivery_block_html": html,
                  "invoice_block_html": this.htmlfactura,
                  "emailDestino": this.isInvitado ? this.formInvitado?.email + ',ventas@lideart.com.mx' : this.usuario.usuario + ',ventas@lideart.com.mx',
                  "telefono": this.isInvitado ? this.formInvitado?.phone : this.telefonito
                }

              } if (this.metodoPago == "Oxxo Pay") {
                this.mensajazo = {

                  "customerName": this.nombreCompleto,
                  "order_name": this.respuestaOV,
                  "date": this.formattedDate,
                  "payment": this.metodoPago + " || Referencia de Oxxo: " + this.oxxxo,
                  "products":
                    this.lineasBomnitas
                  ,
                  "discounts": [
                    Number(this.calculo)
                  ],
                  "name_discount": this.codiguito,
                  "total_products": this.grandTotal,
                  "total_discounts": Number(this.calculo),
                  "total_wrapping": 0,
                  "total_shipping": this.totalEnvio,
                  "total_tax_paid": this.grandTotal / 1.16,
                  "total_paid": this.grandTotal,
                  "carrier": this.modoEntrega + " " + this.mensajepasa,
                  "delivery_block_html": html,
                  "invoice_block_html": this.htmlfactura,
                  "emailDestino": this.isInvitado ? this.formInvitado?.email + ',ventas@lideart.com.mx' : this.usuario.usuario + ',ventas@lideart.com.mx',
                  "telefono": this.isInvitado ? this.formInvitado?.phone : this.telefonito
                }
              } if (this.metodoPago != 'Transferencia' && this.metodoPago != 'Oxxo Pay') {
                this.mensajazo = {

                  "customerName": this.nombreCompleto,
                  "order_name": this.respuestaOV,
                  "date": this.formattedDate,
                  "payment": this.metodoPago,
                  "products":
                    this.lineasBomnitas
                  ,
                  "discounts": [
                    Number(this.calculo)
                  ],
                  "name_discount": this.codiguito,
                  "total_products": this.grandTotal,
                  "total_discounts": Number(this.calculo),
                  "total_wrapping": 0,
                  "total_shipping": this.totalEnvio,
                  "total_tax_paid": this.grandTotal / 1.16,
                  "total_paid": this.grandTotal,
                  "carrier": this.modoEntrega + " " + this.mensajepasa,
                  "delivery_block_html": html,
                  "invoice_block_html": this.htmlfactura,
                  "emailDestino": this.isInvitado ? this.formInvitado?.email + ',ventas@lideart.com.mx' : this.usuario.usuario + ',ventas@lideart.com.mx',
                  "telefono": this.isInvitado ? this.formInvitado?.phone : this.telefonito
                }
              }

              this.textoCargando = "Enviando Correo de Confirmación de Compra..."
              this.checkoutService.mandacorreo(this.mensajazo).subscribe(masdatos => {


                if (this.usuario) {
                  this.email = this.usuario.usuario
                } else {
                  this.email = "";
                }
                let datos = {
                  "email": this.email
                }
                this.shoppingCart.carritoFinalizo(datos).subscribe(data => {

                })




                if (this.metodoPago == 'Oxxo Pay') {
                  const customerData = {
                    name: this.nombreCompleto,
                    email: this.isInvitado ? this.formInvitado?.email : this.usuario.usuario
                  }
                  this.conekta.createCustomer(customerData).subscribe(datitos => {
                    this.cargando = true;
                    this.cliente = datitos;

                    let totalcompra = (this.grandTotal * 100).toString();
                    let totalenvio = (this.totalEnvio * 100).toString();


                    const thirty_days_from_now = (Math.round(Date.now() / 1000 + 60 * 60 * 24 * 30)).toString()

                    const orderData = {

                      "line_items": [{
                        "name": "Pedido Lideart App",
                        "unit_price": parseInt(totalcompra),
                        "quantity": 1
                      }],
                      "shipping_lines": [{
                        "amount": 0,
                      }],
                      "currency": "MXN",
                      "customer_info": {
                        "name": this.nombreCompleto,
                        "email": this.isInvitado ? this.formInvitado?.email : this.usuario.usuario,
                        "phone": "+5215555555555"
                      },
                      shipping_contact: {
                        phone: '+5215555555555',
                        receiver: this.nombreCompleto,
                        address: {
                          street1: "Direccion de Envio",
                          country: 'MX',
                          postal_code: this.isInvitado ? this.formInvitado?.cp || '31114' : this.postcode
                        }
                      },
                      "metadata": {
                        "datos_extra": "1234"
                      },
                      "charges": [{
                        "payment_method": {
                          "type": "cash",
                          "expires_at": thirty_days_from_now
                        }
                      }]
                    }


                    this.conekta.createOrder(orderData).subscribe(async (orderdata) => {
                      this.cargando = false;
                      this.orden = orderdata;
                      this.oxxxo = this.orden.charges.data[0].payment_method.reference;
                      await firstValueFrom(this.http.put(`${NEWAPI_BASE_URL}/api/NewLideartVentas/${this._idCompra}`, {
                        oxxo: this.oxxxo
                      }))
                      this.productitos = this.itemsillos;
                      // this.shoppingCart.deleteCart();

                      if (this.isInvitado) {
                        localStorage.setItem('invitado', `Invitado ${this.formInvitado?.email} - ${this.formInvitado?.phone}`);
                      }

                      // TODO:  Aqui se finaliza la compra


                      this.textoCargando = "Redireccionando a Resumen de Compra..."

                      // (document.getElementById("pago") as any).style.display = "none";

                      // if(window.innerWidth<992){
                      //   this.animacion('comprita');
                      // }
                      // else{
                      //   this.animacion('resumen')
                      // }
                      this.cargando = false;
                      this.apiFacebook.sendEvent('Purchase', this.grandTotal, this.usuario.usuario, this.direccion[0].phone, {
                        external_id: this.customerID,
                        nombre: this.direccion[0].firstname,
                        apellido: this.direccion[0].lastname,
                        estado: this.direccion[0].id_state,
                        ciudad: this.direccion[0].city,
                        country: 'México',
                        codigo_postal: this.direccion[0].postcode
                      });
                      this.tiktokEvents.sendEvent({ event: 'CompletePayment', value: this.grandTotal })

                      this.shoppingCart.setCartData([]);
                      this.enviarEncuesta();
                      setTimeout(() => {
                        if (this.metodoPago != "Kueski Pay") this.router.navigate(['/resumen-compra']);
                      }, 3000);

                      // setTimeout(() => {
                      //   location.reload();
                      // }, 1000);
                      // (document.getElementById("resumen") as any).style.display = "flex";
                    })
                  })
                } else {
                  if (this.isInvitado) {
                    localStorage.setItem('invitado', `Invitado ${this.formInvitado?.email} - ${this.formInvitado?.phone}`);
                  }

                  //TODO: Finalizar compra

                  this.textoCargando = "Redireccionando a Resumen de Compra..."
                  try {
                    if (this.metodoPago == "Web Pay") {

                      if (this.facturame == true) {
                        var aplicameJson = {

                          "nombreDiario": "L-CB_ECOM",
                          "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                          "factura": this.respuestaOV,
                          "diarioCuentaContra": "PTE_WEBPAY",
                          "montoFactura": parseFloat(this.totalpaypalsito),
                          "tipoPago": this.paymentMethod,
                          "numTarjeta": "",
                          "customer": this.customerID,
                          "paymentReference": this.respuestaOV,
                          "fecha": this.formattedDate

                        }

                        // this.checkoutService.aplicarPago(aplicameJson).subscribe(aplicado =>{


                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{


                        // })

                        // })
                      } else {
                        var aplicameJson2 = {

                          "nombreDiario": "L-CB_ECOM",
                          "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                          "factura": this.respuestaOV,
                          "diarioCuentaContra": "PTE_WEBPAY",
                          "montoFactura": parseFloat(this.totalpaypalsito),
                          "tipoPago": this.paymentMethod,
                          "numTarjeta": "",
                          "customer": "CL0001348",
                          "paymentReference": this.respuestaOV,
                          "fecha": this.formattedDate
                        }

                        // this.checkoutService.aplicarPago(aplicameJson2).subscribe(aplicado =>{
                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{
                        // })
                        // })
                      }







                    }

                    if (this.metodoPago == "PayPal") {

                      if (this.facturame == true) {
                        var aplicameJson = {

                          "nombreDiario": "L-CB_ECOM",
                          "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                          "factura": this.respuestaOV,
                          "diarioCuentaContra": "PTE_PAYP",
                          "montoFactura": parseFloat(this.totalpaypalsito),
                          "tipoPago": this.paymentMethod,
                          "numTarjeta": "",
                          "customer": this.customerID,
                          "paymentReference": this.respuestaOV,
                          "fecha": this.formattedDate
                        }

                        // this.checkoutService.aplicarPago(aplicameJson).subscribe(aplicado =>{
                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{
                        // })
                        // })
                      } else {
                        var aplicameJson2 = {

                          "nombreDiario": "L-CB_ECOM",
                          "description": this.currentDateTime2 + ", ventas01.lideart," + this.respuestaOV,
                          "factura": this.respuestaOV,
                          "diarioCuentaContra": "PTE_PAYP",
                          "montoFactura": parseFloat(this.totalpaypalsito),
                          "tipoPago": this.paymentMethod,
                          "numTarjeta": "",
                          "customer": "CL0001348",
                          "paymentReference": this.respuestaOV,
                          "fecha": this.formattedDate
                        }

                        // this.checkoutService.aplicarPago(aplicameJson2).subscribe(aplicado =>{
                        // this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{
                        // })
                        // })
                      }


                      // this.checkoutService.aplicarPago(aplicameJson).subscribe(aplicado =>{

                      //   var liberameJson = {
                      //     "SalesOrderNum": this.respuestaOV,
                      //     "DeliveryMode": this.modoEntrega,
                      //     "PaymentMethod": this.paymentMethod,
                      //     "PaymentTermsName": this.PaymentTermsName,
                      //     "NumeroTelefono": "",
                      //     "CorreoElectronico": this.usuario.usuario,
                      //     "Sucursal": "CHIHUAHUA",
                      //   }

                      //   this.checkoutService.liberaAlmacen(liberameJson).subscribe(liberado =>{

                      //   })

                      // })
                    }
                    //! TEMPORAL KUESKI
                    if (this.metodoPago == "Kueski Pay") {
                      //TODO: Hacer que pueda funcionar con cot en caso de no haber OV
                      this.checkoutService.kueskiPayTemp({ costo: this.grandTotal, ov: this.respuestaOV || this.respuestaCotizacion }).subscribe(r => {

                        console.log(r)

                        //TODO: Investigar como hacer esta redireccion (y la de vuelta) en la app
                        this.document.location.href = r.data.callback_url
                      })
                    }
                    //! TEMPORAL MercadoPago
                    if (this.metodoPago == "Mercado Pago") {
                      //TODO: Hacer que pueda funcionar con cot en caso de no haber OV
                      this.http.post<{ id: string, init_point: string }>('https://payments.lideart.com.mx/lideart/pagos/TMPMercadoPagoPreference', {
                        ov: this.respuestaOV || this.respuestaCotizacion,
                        productos: JSON.parse(localStorage.getItem('cart') || '[]')
                      }).subscribe(r => {
                        console.log(`RESPUESTA MP`, { mercadoPago: r })
                        if (this.APP_CONTEXT.isApp) {
                          //TODO: Investigar como hacer esta redireccion (y la de vuelta) en la app
                          window.open(r.init_point)
                        }
                        else {
                          // const nuevaVentana = window.open(r.init_point, '_blank', 'width=800,height=600');
                          this.document.location.href = r.init_point

                        }
                        // this.document.location.href = r.data.callback_url
                      })
                    }
                  } catch (error) {

                  }

                  this.cargando = false;
                  this.apiFacebook.sendEvent('Purchase', this.grandTotal, this.usuario.usuario, this.direccion[0].phone, {
                    external_id: this.customerID,
                    nombre: this.direccion[0].firstname,
                    apellido: this.direccion[0].lastname,
                    estado: this.direccion[0].id_state,
                    ciudad: this.direccion[0].city,
                    country: 'México',
                    codigo_postal: this.direccion[0].postcode
                  });
                  this.tiktokEvents.sendEvent({ event: 'CompletePayment', value: this.grandTotal })
                  this.enviarEncuesta();
                  this.shoppingCart.setCartData([]);
                  setTimeout(() => {
                    if (this.metodoPago != "Kueski Pay") this.router.navigate(['/resumen-compra']);
                  }, 3000);

                  // setTimeout(() => {
                  //   location.reload();
                  // }, 1000);
                  // (document.getElementById("pago") as any).style.display = "none";

                  // if(window.innerWidth<992){
                  //   this.animacion('comprita');
                  // }
                  // else{
                  //   this.animacion('resumen')
                  // }

                  // (document.getElementById("resumen") as any).style.display = "flex";


                  // this.shoppingCart.deleteCart();

                  // if(window.innerWidth<992){
                  //   this.animacion('comprita');
                  // }
                  // else{
                  //   this.animacion('resumen')
                  // }
                  // (document.getElementById("resumen") as any).style.display = "flex";
                }





              })

            })
          } catch (error) {

          }




        })
      }, 5000);


    }




  }

  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }


  openModal2(): void {
    this.isModalOpen2 = true;
  }

  closeModal2(): void {
    this.isModalOpen2 = false;
  }


  aceptacion() {
    this.acepto = true;
  }

  tarjetas() {

    //console.log("webpay 1")
    // document.getElementById('comprame').style.display = "none";
    this.sepuede = false;
    this.radioPago = false;
    this.metodoPago = "Web Pay"
    this.valorRadio = 3;
    this.paymentMethod = "28";
    this.PaymentTermsName = "CONTADO";
    // this.metodoPago = "Web Pay";
    //console.log("webpay 2")

    //document.getElementById('open-modal')!.click();
    //console.log("webpay 3")
    //document.getElementById('modalsito')!.style.display = "flex";
    //console.log("webpay 4")
    this.webPay();
    // (<HTMLInputElement>document.getElementsByName("paypal")[0].children[0].children[0].children[2]).disabled = false;
    // (<HTMLInputElement>document.getElementsByName("transferencia")[0].children[0].children[0].children[2]).disabled = false;
    // (<HTMLInputElement>document.getElementsByName("tarjetas")[0].children[0].children[0].children[2]).disabled = true;


  }

  webPay() {
    //console.log("webpay 5")
    clearInterval(this.intervalopago);
    //console.log("webpay 6")
    const roundTo = function (num: number, places: number) {
      const factor = 10 ** places;
      return Math.round(num * factor) / factor;
    };
    //console.log("webpay 7")
    var lejson = { "total": roundTo(this.grandTotal, 2), "email": this.usuario.usuario }
    this.checkoutService.webpay(lejson).subscribe(data => {

      this.datosWebPay = data;
      this.iframe = this.datosWebPay.ligaWebPay;
      this.urlsafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
      this.intervalopago = setInterval(() => {

        this.checkoutService.checaPago(this.numero_carro).subscribe(response => {
          this.respuestapago = response;
          var resPago = this.respuestapago;



          if (resPago > 0) {
            if (resPago == 1) {
              clearInterval(this.intervalopago);
              this.relizarCompra();
              this.cancel();



            }
            else {
              alert('Error al Procesar pago');
              clearInterval(this.intervalopago);
            }
          }

        })
      }, 3000)

    })

  }

  async seleccionaDireccion() {
    Swal.fire({
      icon: "error",
      title: "Selecciona una dirección.",
      showConfirmButton: false,
      timer: 2000
    });
  }

  async direccionMala() {
    Swal.fire({
      icon: "error",
      title: "",
      showConfirmButton: false,
      timer: 2000
    });

    Swal.fire({
      title: "¡Datos Faltantes!",
      text: "La dirección seleccionada tiene datos incompletos, presiona en corregir para completar información.",
      icon: "error",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Corregir"
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/perfil/0']);
      }
    });
  }

  async seleccionaEnvio() {
    Swal.fire({
      icon: "error",
      title: "Selecciona la opción de envío.",
      showConfirmButton: false,
      timer: 2000
    });
  }

  async errorAcuerdo() {
    const toast = await this.toastController.create({
      message: 'Acepta los terminos y condiciones para seleccionar este metodo de pago',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async seleccionaPago() {
    const toast = await this.toastController.create({
      message: 'Asegurate de seleccionar metodo de pago y aceptar los terminos.',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async modalReg() {
    const modal = await this.modalCtrl.create({
      component: CheckoutComponent,
      cssClass: 'modalRegistroCss',
      initialBreakpoint: 1,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Finalizando Compra...',
      duration: 8000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();

  }



  cancel() {
    this.modal.dismiss(null, 'cancel');
    document.getElementById('modalsito')!.style.display = "none";
  }

  cancel10() {
    this.modal.dismiss(null, 'cancel');
    document.getElementById('modalsito10')!.style.display = "none";
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  setOpen2(isOpen2: boolean) {
    this.isModalOpen2 = isOpen2;
  }

  setOpen3(isOpen3: boolean) {
    this.isModalOpen3 = isOpen3;
  }

  setOpen4(isOpen4: boolean) {
    this.isModalOpen4 = isOpen4;
  }

  cancel2() {
    this.modal2.dismiss(null, 'cancel');
  }
  cancel3() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(this.name, 'confirm');
  }

  cancel4() {
    this.modal3.dismiss(null, 'cancel');
  }

  animacion(valor: string) {
    document.getElementById(valor)!.style.display = "flex";
  }

  quitaAnimacion() {
    document.getElementById('comprita')!.style.display = "none";
  }


  bloqueo() {
    // if (this.facturame2 == false) {
    //   this.errorAcuerdo();
    // }
  }

  registraCarritoEnvio() {

    if (this.usuario) {
      this.email = this.usuario.usuario
    } else {
      this.email = "";
    }
    let datos = {
      "email": this.email
    }

    this.shoppingCart.carritoPasoEnvio(datos).subscribe(data => {

    })


  }

  closePopup(): void {
    this.close.emit();
  }

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  stopPropagation2(event: MouseEvent): void {
    event.stopPropagation();
  }

  registraCarritoUsuario() {

    if (this.usuario) {
      this.email = this.usuario.usuario
    } else {
      this.email = "";
    }
    let datos = {
      "email": this.email
    }

    this.shoppingCart.carritoPasoUsuario(datos).subscribe(data => {

    })
  }

  registraCarritoDireccion() {
    if (this.usuario) {
      this.email = this.usuario.usuario
    } else {
      this.email = "";
    }
    let datos = {
      "email": this.email
    }
    this.shoppingCart.carritoPasoDireccion(datos).subscribe(data => {

    })
  }

  async clienteSubdistribuidor(): Promise<void> {
    try {
      const data: any = await firstValueFrom(this.checkoutService.clienteSubdistribuidor(this.customerID));
      const parsedData = Number(data);
      this.subdistribuidor = parsedData >= 1 ? 1 : 0;
    } catch (error) {
      this.subdistribuidor = 0;
    }
  }

  //Esto s solo utilizado en compras como INVITADO
  handleFormSubmitted(formData: any) {
    console.log('Formulario enviado:', formData);
    const currentItems = this.shoppingCart.cartItems.getValue();


    const _transformData = this._valCarrito.convertCartDataToInputProduct(currentItems);
    //Valida que haya stock en TODOS los productos del carrito
    this._valCarrito.isAllWithStock({ products: _transformData }).then((res) => {
      // alert('Respuesta de stock: ' + res);
      if (!res) {
        this.router.navigate(['/cart']);
        return;
      }
      this.itemsillos = currentItems as unknown as ItemCarrito[];
      console.log("Carrito INFO", this.itemsillos);
      // Aquí puedes manejar la información del formulario como desees
      this.formInvitado = formData
      this.checarDireccion = false;
      if (!this.formInvitado?.envio) {
        this.radioEnvio = true
        this.envioRecoge()
        this.continuarEnvio()

        console.log("me meti al contunuar envio")
      } else {
        console.log("me meti al contunuar direccion")
        this.paqueteExpress = 191.40;
        this.continuarDireccion()

      }
    })

  }

  splitFullName(fullName: string): { firstName: string, lastName: string } {
    const nameParts = fullName.trim().split(' ');
    const firstName = nameParts.slice(0, -1).join(' ');
    const lastName = nameParts.slice(-1).join(' ');
    return { firstName, lastName };
  }

  abrirModalCorreo() {
    const correo = this.isInvitado ? this.formInvitado?.email : this.usuario.usuario;
    const inputCorreo = `
      <div id="correos-container">
        <p id="correo-mensaje">Este es el correo al cual se enviará la confirmación de tu pedido:</p>
        <div class="correo-item" style="display: flex; flex-direction: column; align-items: center;">
          <div class="correo-usuario" style="display: flex; align-items: center;">
            <label id="correo-label" style="font-weight: bold;margin: 10px;">${correo}</label>
            <i id="modificar-correo-icon" class="fa fa-pencil" style="cursor: pointer; margin-left: 10px;"></i>
          </div>
          <input type="email" id="correo-input" class="swal2-input" placeholder="Correo" value="${correo}" style="display: none; width: 80%;">
          <button id="guardar-correo" class="guardar-correo-btn" style="display: none; margin-top: 10px;">Guardar</button>
        </div>
      </div>
      <div id="agregar-correo-container">
        <label>Enviar una copia a otra dirección de correo</label>
        <input type="checkbox" id="agregar-correo-check">
      </div>
    `;

    Swal.fire({
      title: 'Confirmación de Correo',
      html: inputCorreo,
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: 'btn btn-primary',
      },
      preConfirm: async () => {
        const correos = Array.from(document.querySelectorAll('.correo-item label') as NodeListOf<HTMLLabelElement>).map(label => label.innerText);
        for (const correo of correos) {
          const response = await fetch('https://new.lideart.com.mx/Automatizacion/apis/emailValidation.php?email=' + correo);
          const result = await response.json();
          if (!result.valid) {
            Swal.showValidationMessage(`Correo inválido: ${correo}. ${result.message}`);
            return false;
          }
        }
        return correos;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.correosConfirmacion = result.value;
        console.log('Correos de confirmación:', this.correosConfirmacion);
        this.aceptacion();

        //* Llamada inicial api db
        if (this._idCompra != 0) return; //Previene que se generen multiples compras como invitado
        const objetoVentita = {
          correo: this.isInvitado ? `Invitado ${this.formInvitado?.email} - ${this.formInvitado?.phone}` : this.usuario.usuario,
          nombre: `${this.nombreCompleto} ${this.isInvitado ? '(Invitado)' : ''}`,
          pais: "Mexico",
          estado: this.isInvitado ? this.formInvitado?.estado || 'Invitado pasa' : this.nombreEstado,
          ciudad: this.isInvitado ? this.formInvitado?.ciudad || 'Invitado pasa' : this.direccion[this.radioDireccion].city,
          colonia: this.isInvitado ? this.formInvitado?.colonia || 'Invitado pasa' : this.direccion[this.radioDireccion].colony,
          cp: this.isInvitado ? this.formInvitado?.cp || 'Invitado pasa' : this.direccion[this.radioDireccion].postcode,
          direccion: this.isInvitado ? this.formInvitado?.direccion || 'Invitado pasa' : this.direccion[this.radioDireccion].address1,
          fecha: this.fechaActual,
          hora: this.now,
          tipo_entrega: this.tipoEnvio,
          metodo_pago: this.metodoPago,
          paqueteria: this.totalEnvio,
          iva: ((this.grandTotal + this.totalEnvio) * .16).toFixed(2),
          subtotal: (this.grandTotal / 1.16).toFixed(2),
          total: this.grandTotal + this.totalEnvio.toFixed(2),
          cotizacion: this.MP_cotizacionPre !== '' ? this.MP_cotizacionPre : this.respuestaCotizacion, //* Campo inicialmente vacío
          ov: this.respuestaOV || "", //* Campo inicialmente vacío
          oxxo: this.oxxxo, //* Campo inicialmente vacío
          arregloEtiquetas: JSON.stringify(this.arrayEtiquetas),
          arrayCarrito: JSON.stringify(this.itemsillos),
          arrayCorreo: this.correosConfirmacion
        }



        this.http.post(`${NEWAPI_BASE_URL}/api/NewLideartVentas/registrarGuardarVenta`, objetoVentita).subscribe((res: any) => {
          console.log('*Objeto de venta respuesta:', { res });
          if (res.status = 1) {
            this._idCompra = res.data.idCompra;
            this.initConfig();
            console.log('ID de compra:', this._idCompra);
          }
        });
      }
      else {
        this.facturame2 = false
      }
    });

    const modificarCorreoIcon = document.getElementById('modificar-correo-icon') as HTMLElement;
    const guardarCorreoBtn = document.getElementById('guardar-correo') as HTMLButtonElement;
    const correoLabel = document.getElementById('correo-label') as HTMLLabelElement;
    const correoInput = document.getElementById('correo-input') as HTMLInputElement;
    const agregarCorreoCheck = document.getElementById('agregar-correo-check') as HTMLInputElement;
    const agregarCorreoContainer = document.getElementById('agregar-correo-container') as HTMLDivElement;
    const correoMensaje = document.getElementById('correo-mensaje') as HTMLParagraphElement;
    const confirmButton = document.querySelector('.swal2-confirm') as HTMLButtonElement;

    if (modificarCorreoIcon && guardarCorreoBtn && correoLabel && correoInput && agregarCorreoCheck && agregarCorreoContainer && correoMensaje && confirmButton) {
      agregarCorreoContainer.style.display = 'block'; // Mostrar el contenedor inicialmente

      modificarCorreoIcon.addEventListener('click', () => {
        // Ocultar el correo actual y mostrar el input y botón de guardar
        correoLabel.style.display = 'none'; // Ocultar el label actual
        correoInput.style.display = 'block'; // Mostrar el input
        guardarCorreoBtn.style.display = 'block'; // Mostrar el botón de guardar
        modificarCorreoIcon.style.display = 'none'; // Ocultar el ícono de modificar
        agregarCorreoContainer.style.display = 'none'; // Ocultar el contenedor del checkbox al modificar
        confirmButton.disabled = true; // Deshabilitar el botón "Continuar" mientras se edita

        // Mantener visibles los correos previos (si existiesen)
        const container = document.getElementById('correos-container');
        if (container) {
          Array.from(container.children).forEach(child => {
            if (child !== agregarCorreoContainer) {
              (child as HTMLElement).style.display = 'block'; // Asegurarse de que todos los correos sean visibles
            }
          });
        }
      });

      guardarCorreoBtn.addEventListener('click', async () => {
        if (await this.validarCorreoAPI(correoInput.value)) {
          correoLabel.innerText = correoInput.value;
          correoLabel.style.display = 'block'; // Mostrar el correo editado
          correoInput.style.display = 'none'; // Ocultar el input
          modificarCorreoIcon.style.display = 'block'; // Mostrar el ícono de modificar
          guardarCorreoBtn.style.display = 'none'; // Ocultar el botón de guardar
          correoMensaje.style.display = 'block'; // Mostrar el mensaje después de guardar el correo
          confirmButton.disabled = false; // Habilitar el botón "Continuar"
          agregarCorreoContainer.style.display = 'block'; // Asegurarse de que el contenedor del checkbox se mantenga visible
          correoInput.style.borderColor = ''; // Restablecer el borde del input

          // Eliminar el mensaje de validación de SweetAlert2
          const validationMessage = Swal.getValidationMessage();
          if (validationMessage) {
            validationMessage.style.display = 'none';
          }

          // Verificar que no se oculten los correos previos (si existen)
          const container = document.getElementById('correos-container');
          if (container) {
            Array.from(container.children).forEach(child => {
              (child as HTMLElement).style.display = 'block'; // Mostrar todos los correos
            });
            if (container.children.length > 4) {
              agregarCorreoContainer.style.display = 'none'; // Ocultar el contenedor si ya hay 3 correos
            }
          }
        } else {
          correoInput.style.borderColor = 'red';
        }
      });

      agregarCorreoCheck.addEventListener('change', () => {
        const container = document.getElementById('correos-container');
        const copiaACorreoLabel = document.getElementById('copia-a-correo'); // Contenedor del mensaje 'Con copia a:'

        if (agregarCorreoCheck.checked) {
          agregarCorreoContainer.style.display = 'none'; // Ocultar el contenedor cuando se selecciona el checkbox
          modificarCorreoIcon.style.display = 'none'; // Ocultar el ícono "Modificar"
          correoMensaje.style.display = 'none'; // Ocultar el mensaje
          confirmButton.disabled = true; // Deshabilitar el botón "Continuar"

          if (container && container.children.length <= 5) {
            if (!copiaACorreoLabel) {
              const copiaAMensaje = document.createElement('p');
              copiaAMensaje.id = 'copia-a-correo';
              copiaAMensaje.innerText = 'Con copia a:';
              copiaAMensaje.style.fontWeight = 'bold';
              container.appendChild(copiaAMensaje);
            }

            // Agregar nuevo campo de correo
            const correoItem = document.createElement('div');
            correoItem.className = 'correo-item';
            correoItem.innerHTML = `
              <input type="email" class="swal2-input" placeholder="Correo" style="width: 80%;">
              <button class="guardar-correo-btn" style="margin-top: 10px;">Guardar</button>
            `;
            container.appendChild(correoItem);

            const guardarCorreoBtn = correoItem.querySelector('.guardar-correo-btn') as HTMLButtonElement;
            if (guardarCorreoBtn) {
              guardarCorreoBtn.addEventListener('click', async () => {
                const input = correoItem.querySelector('input') as HTMLInputElement;
                if (input && await this.validarCorreoAPI(input.value)) {
                  const label = document.createElement('label');
                  label.innerText = input.value;
                  label.style.fontWeight = 'bold';
                  correoItem.replaceChild(label, input);
                  guardarCorreoBtn.style.display = 'none';

                  agregarCorreoCheck.checked = false; // Deseleccionar el checkbox
                  modificarCorreoIcon.style.display = 'block'; // Mostrar el ícono "Modificar" después de guardar el correo
                  correoMensaje.style.display = 'block'; // Mostrar el mensaje después de guardar el correo
                  confirmButton.disabled = false; // Habilitar el botón "Continuar"
                  input.style.borderColor = ''; // Restablecer el borde del input

                  // Eliminar el mensaje de validación de SweetAlert2
                  const validationMessage = Swal.getValidationMessage();
                  if (validationMessage) {
                    validationMessage.style.display = 'none';
                  }

                  if (container) {
                    Array.from(container.children).forEach(child => {
                      (child as HTMLElement).style.display = 'block'; // Mostrar todos los correos
                    });
                    if (container.children.length <= 5) {
                      agregarCorreoContainer.style.display = 'block'; // Mostrar el contenedor después de guardar el correo
                    } else {
                      agregarCorreoContainer.style.display = 'none'; // Ocultar el contenedor si ya hay 3 correos
                    }
                  }
                } else if (input) {
                  input.style.borderColor = 'red';
                }
              });
            }
          }
        } else {
          agregarCorreoContainer.style.display = 'block'; // Mostrar el contenedor si se deselecciona el checkbox
          modificarCorreoIcon.style.display = 'block'; // Mostrar el ícono "Modificar" si se deselecciona el checkbox
          correoMensaje.style.display = 'block'; // Mostrar el mensaje si se deselecciona el checkbox
          confirmButton.disabled = false; // Habilitar el botón "Continuar"
          if (container) {
            Array.from(container.children).forEach(child => {
              (child as HTMLElement).style.display = 'block'; // Mostrar todos los correos
            });
            // Eliminar el mensaje 'Con copia a:' si no hay correos adicionales
            const copiaACorreoLabel = document.getElementById('copia-a-correo');
            if (copiaACorreoLabel) {
              copiaACorreoLabel.remove();
            }
          }
        }
      });

      // Verificar el número de correos al cargar el modal
      const container = document.getElementById('correos-container');
      if (container && container.children.length > 4) {
        agregarCorreoContainer.style.display = 'none'; // Ocultar el contenedor si ya hay 3 correos
      }
    }
  }

  async validarCorreoAPI(correo: string): Promise<boolean> {
    const response = await fetch('https://new.lideart.com.mx/Automatizacion/apis/emailValidation.php?email=' + correo);
    const result = await response.json();
    if (!result.valid) {
      Swal.showValidationMessage(`Correo inválido: ${correo}. ${result.message}`);
      return false;
    }
    return true;
  }

  validarCorreo(correo: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(correo);
  }
}
